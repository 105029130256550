import PropTypes from 'prop-types';
import Spinner from '../Utils/Spinner';
import React from 'react';
import { Dropdown, DropdownToggle } from 'mdbreact';
import CustomerDropdownMenu from '../CustomerDropdownMenu';
import { FormattedMessage } from 'react-intl';

const Customers = ({ userDetails, currentCustomer, customerHandler }) => {
    return (
        <Dropdown className="dashboard__account-switch account-dropdown-menu">
            <DropdownToggle nav caret>
                <span className="dashboard__account-switch__label">
                    <FormattedMessage id="customer.label" />:{' '}
                    <span className="dashboard__account-switch__label__account">
                        {!currentCustomer ? (
                            <Spinner
                                width={15}
                                height={15}
                                className="spinner--white"
                            />
                        ) : (
                            `${currentCustomer?.username} - ${currentCustomer?.name}`
                        )}
                    </span>
                </span>
            </DropdownToggle>
            {userDetails && (
                <CustomerDropdownMenu
                    customersList={userDetails.customers}
                    selected={currentCustomer?.id}
                    customerHandler={customerHandler}
                />
            )}
        </Dropdown>
    );
};

Customers.propTypes = {
    userDetails: PropTypes.object,
    customerHandler: PropTypes.func,
};

export default Customers;

import PropTypes from 'prop-types';
import React from 'react';
import ButtonWithSpinner from '../../Utils/ButtonWithSpinner';

const DownloadAssortmentButton = ({
    handleDownloadAssortment,
    downloadFormat,
    loading,
}) => (
    <ButtonWithSpinner
        width={11}
        height={11}
        className={`delivery__documents__btn btn-sm btn-flat btn ${
            downloadFormat === 'excel' ? 'btn-success' : 'btn-red'
        }`}
        onClick={handleDownloadAssortment}
        label={`table.download_assortment_formal.${downloadFormat}`}
        isLoading={loading}
    />
);

DownloadAssortmentButton.propTypes = {
    handleDownloadAssortment: PropTypes.func,
    downloadFormat: PropTypes.string,
    loading: PropTypes.bool,
};

export default DownloadAssortmentButton;

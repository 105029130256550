import React from 'react';
import ReactTable from 'react-table';
import PropTypes, { bool } from 'prop-types';
import { injectIntl } from 'react-intl';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import { openFilterable, toggleFilterable } from '../../actions/table';
import { connect } from 'react-redux';
import { closeGrey } from '../../assets/images/icons';
import * as qs from 'qs';
import history from '../../history';
import debounce from 'lodash.debounce';
import * as R from 'ramda';
import { ReactTableDefaults } from 'react-table';
import swal from 'sweetalert2';
import { showProductDetails } from '../../actions/productDetails';

const ReactTableFixedColumns = withFixedColumns(ReactTable);

class Table extends React.Component {
    isRenderedSearchHeader = false;

    constructor(props) {
        super(props);

        const pageSize = this.props.pageSize ? this.props.pageSize : 50;

        this.state = {
            wrapperRef: undefined,
            headerWidth: 0,
            fixedHeader: false,
            fixedSearch: false,
            fixedSearchAdditional: false,
            itemsCount: 0,
            originalPageSize: pageSize,
            pageSize: pageSize,
            pages: null,
            forceCloseFiltering: false,
            filtered: this.getFiltersInQueryParams(),
            lastRequestParams: {},
        };
    }

    componentDidMount() {
        if (this.props.withStickyHeader) {
            document.addEventListener('scroll', this.handleScroll);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        this.handleScroll();
        if (this.props.filterable) {
            if (!this.isRenderedSearchHeader) {
                this.addFilterClearFeature();
            }
            this.appendFiltersHeader();
        }

        if (
            prevProps.itemsCount !== this.props.itemsCount &&
            typeof this.props.itemsCount !== 'undefined'
        ) {
            const pageSize =
                this.state.originalPageSize < this.props.itemsCount
                    ? this.state.originalPageSize
                    : this.props.itemsCount;
            this.setState({
                pageSize: pageSize,
            });
        }
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (!props.itemsCount && props.data) {
            //FIX FOR OTHER TABLES WHERE IS NOT API CALL YET
            return {
                pages: Math.ceil(props.data.length / state.pageSize),
            };
        }

        if (state.itemsCount !== props.itemsCount) {
            newState.pages = Math.ceil(props.itemsCount / state.pageSize);
            newState.pageSize =
                state.originalPageSize < props.itemsCount
                    ? state.originalPageSize
                    : props.itemsCount;
        }

        return newState;
    }

    setWrapperRef = (node) => {
        this.setState({
            wrapperRef: node,
        });
    };

    setFixedHeader = (status) => {
        if (this.state.fixedHeader && status) {
            return;
        }

        this.setState({
            fixedHeader: status,
            headerWidth: this.state.wrapperRef.getBoundingClientRect().width,
        });
    };

    setFixedSearch = (status) => {
        if (this.state.fixedSearch && status) {
            return;
        }

        this.setState({
            fixedSearch: status,
        });

        const searchBlock = document.getElementById('main-search');
        if (searchBlock) {
            if (status) {
                searchBlock.classList.add('search-fixed');
            } else {
                searchBlock.classList.remove('search-fixed');
            }
        }
    };

    setFixedSearchAdditional = (status) => {
        if (this.state.fixedSearchAdditional && status) {
            return;
        }

        this.setState({
            fixedSearchAdditional: status,
        });

        const searchBlock = document.getElementById('main-search');
        if (searchBlock) {
            if (status) {
                searchBlock.classList.add('search-fixed-additional');
            } else {
                searchBlock.classList.remove('search-fixed-additional');
            }
        }
    };

    handleScroll = () => {
        const node = this.state.wrapperRef;

        let searchBlockTop = this.props.withNotification ? 490 : 400;
        if (document.querySelector('#main-search')?.closest('.delivery')) {
            searchBlockTop = 400;
        }

        if (document.querySelector('#main-search')?.closest('.promotions')) {
            searchBlockTop = 340;
        }

        if (node.getBoundingClientRect().top) {
            if (node.getBoundingClientRect().top < searchBlockTop) {
                this.state.fixedSearch === false && this.setFixedSearch(true);
            } else {
                this.state.fixedSearch && this.setFixedSearch(false);
            }

            if (
                node.getBoundingClientRect().top < 85 &&
                this.state.fixedSearch
            ) {
                this.state.fixedSearchAdditional === false &&
                    this.setFixedSearchAdditional(true);
            } else {
                this.state.fixedSearchAdditional &&
                    this.setFixedSearchAdditional(false);
            }
        }

        if (
            node.getBoundingClientRect().top < 225 &&
            this.props.withStickyHeader
        ) {
            this.state.fixedHeader === false && this.setFixedHeader(true);
        } else {
            this.state.fixedHeader && this.setFixedHeader(false);
        }
    };

    appendFiltersHeader = () => {
        //TODO Rewrite to JSX
        if (document.getElementsByClassName('filters-title').length > 0) {
            return;
        }
        const { intl } = this.props;

        const filtersDiv = document.getElementsByClassName('-filters');
        const filtersChild = document.querySelector('.-filters .rt-tr');
        const title = document.createElement('p');

        const closeBtn = document.createElement('span');
        closeBtn.className = 'filters-lnk';
        closeBtn.textContent = intl.formatMessage({
            id: 'product.close_filter',
        });
        closeBtn.onclick = () => this.props.toggleFilterable();

        !this.props.isFairTable && title.appendChild(closeBtn);

        title.className = 'filters-title';
        title.onclick = () => {
            this.initFiltersActionRequestModal();
            this.setState({
                forceCloseFiltering: true,
            });
        };

        //const headerDiv = document.getElementsByClassName('-header');
        //filtersDiv[0].style = `width: ${headerDiv[0].clientWidth}px`;

        filtersDiv[0].insertBefore(title, filtersChild);
    };

    initFiltersActionRequestModal = () => {
        if (this.state.filtered.length > 0) {
            const { intl } = this.props;

            const swalWithBootstrapButtons = swal.mixin({
                confirmButtonClass: 'btn btn-brand btn-sm btn-flat',
                cancelButtonClass: 'btn btn-grey btn-sm btn-flat',
                buttonsStyling: false,
            });

            swalWithBootstrapButtons({
                title: intl.formatMessage({
                    id: 'table.filters.action_modal.title',
                }),
                showCancelButton: true,
                confirmButtonText: intl.formatMessage({
                    id: 'table.filters.action_modal.keep',
                }),
                cancelButtonText: intl.formatMessage({
                    id: 'table.filters.action_modal.reset',
                }),
                reverseButtons: true,
            }).then((result) => {
                if (!result.value) {
                    this.setState({
                        filtered: [],
                    });
                }
            });
        }
    };

    addFilterClearFeature = () => {
        this.isRenderedSearchHeader = true;

        Object.assign(ReactTableDefaults.column, {
            Filter: (cell) => (
                <React.Fragment>
                    <input
                        type="text"
                        value={cell.filter ? cell.filter.value : ''}
                        onChange={(e) => cell.onChange(e.target.value)}
                        placeholder={
                            cell.column.filterPlaceholder
                                ? this.props.intl.formatMessage({
                                      id: cell.column.filterPlaceholder,
                                  })
                                : ''
                        }
                    />
                    <span
                        className="filter-clear"
                        onClick={() => {
                            cell.onChange('');
                            cell.filter = undefined;
                        }}
                    >
                        <img src={closeGrey} alt="clear" />
                    </span>
                </React.Fragment>
            ),
        });
    };

    scrollToTableTop = () => {
        const node = this.state.wrapperRef;

        if (node) {
            let topOffset = 250;
            const blocks = document.getElementsByClassName('container-fluid');
            if (blocks.length) {
                const block = blocks[0];
                topOffset = block.getBoundingClientRect().height;
            }

            window.scrollTo(0, topOffset);

            //node.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    onPageSizeChange = (pageSize) => {
        this.setState(
            {
                pageSize,
                originalPageSize: pageSize,
            },
            () => {
                if (!this.props.manual) {
                    return;
                }
                const queryParams = qs.parse(history.location.search.slice(1));
                queryParams.limit = pageSize;
                this.props.onFetchData(queryParams);
            }
        );
    };

    onFetchData = (state) => {
        if (!this.props.manual) {
            //Do not add params to url when manual is not set. (means we still use fake data)
            return;
        }

        const queryParams = qs.parse(history.location.search.slice(1)); //Will be used for delete old filters & search
        const queryParamsOriginal = { ...queryParams }; //Will be used in comparasion

        const params = {
            page: (++state.page).toString(),
            limit: this.state.originalPageSize,
        };

        if (state.sorted.length) {
            params.sort = `${state.sorted[0].id},${
                state.sorted[0].desc ? 'DESC' : 'ASC'
            }`;
        }

        if (state.filtered.length) {
            if (!this.state.forceCloseFiltering) {
                this.props.openFilterable();
            }

            state.filtered.forEach((filter) => {
                params[`s_${filter.id}`] = filter.value;
            });

            params.filtered = '1';

            this.setState({
                filtered: state.filtered,
            });
        } else {
            params.filtered = '0';
        }

        //Clear all search filters as it was hard to determine if is removed.
        //Consider change to Ramda to make new copy of queryParams with deleted. to omit double variable.
        this.getFilterableColumns().forEach((column) => {
            if (queryParams[`s_${column.id}`]) {
                delete queryParams[`s_${column.id}`];
            }
        });

        params.mylist = this.props.selectedProductType === 1 ? 1 : 0;
        params.kslist = this.props.selectedProductType === 2 ? 1 : 0;

        const newParams = { ...queryParams, ...params };
        const { limit, ...newParamsWithoutLimit } = newParams;

        if (
            !R.equals(newParamsWithoutLimit, queryParamsOriginal) &&
            // || R.isEmpty(this.props.data)
            !R.equals(this.state.lastRequestParams, newParams)
        ) {
            this.setState({ lastRequestParams: newParams });
            this.props.onFetchData(newParams); //Fire original onFetchData from Container Component
        }

        history.push({
            ...this.props.location,
            search: qs.stringify(newParamsWithoutLimit),
        });

        if (newParams.s_all !== undefined) {
            this.scrollToTableTop();
        }
    };

    onFetchDataWithDebounce = debounce(this.onFetchData, 500);

    fetchStrategy = (state) => {
        if (this.props.filterable) {
            return this.onFetchDataWithDebounce(state);
        } else {
            return this.onFetchData(state);
        }
    };

    onFilteredChange = (column) => {
        this.setState({
            filtered: column,
        });
    };

    getFiltersInQueryParams = () => {
        const queryParams = qs.parse(history.location.search.slice(1));
        const columns = this.getFilterableColumns();

        const appliedFilters = [];
        columns.forEach((column) => {
            if (queryParams[`s_${column.id}`] !== undefined) {
                appliedFilters.push({
                    id: column.id,
                    value: queryParams[`s_${column.id}`],
                });
            }
        });

        return appliedFilters;
    };

    getFilterableColumns = () => {
        return R.filter(R.propEq('filterable', undefined), this.props.columns);
    };

    render() {
        const {
            data,
            className,
            onClick,
            columns,
            filterable,
            intl,
            showPaginationBottom,
            showProductDetails,
            showPageSizeOptions,
            addHoverClassOnColumn,
            loading,
            highlight,
            deliveryDate,
            resolveData,
            hideHead,
            hideBody,
            isFairFixed,
            ...rest
        } = this.props;

        return (
            <div
                ref={this.setWrapperRef}
                className={`table table-preferences ${
                    loading ? 'table--loading' : ''
                } ${hideHead ? 'table--headHidden' : ''} ${
                    hideBody ? 'table--bodyHidden' : ''
                } ${className}`}
                onClick={onClick}
            >
                <ReactTableFixedColumns
                    {...rest}
                    minRows={0}
                    data={data}
                    columns={columns}
                    pageSize={this.props.pageSize || this.state.pageSize}
                    pages={this.state.pages}
                    className={`table__rt ${
                        highlight ? '-highlight' : ''
                    } -striped ${
                        this.state.fixedHeader
                            ? this.props.isFairFixed
                                ? 'table__rt--fixed-header-cart'
                                : 'table__rt--fixed-header'
                            : ''
                    } ${this.props.isFairTable ? '-fair-table' : ''}`}
                    filterable={filterable}
                    filtered={this.state.filtered}
                    resizable={false}
                    previousText={intl.formatMessage({
                        id: 'table.footer.prev_text',
                    })}
                    nextText={intl.formatMessage({
                        id: 'table.footer.next_text',
                    })}
                    pageText={intl.formatMessage({
                        id: 'table.footer.page_text',
                    })}
                    ofText={intl.formatMessage({ id: 'table.footer.of_text' })}
                    noDataText={intl.formatMessage({
                        id: 'table.no_data_text',
                    })}
                    rowsText={intl.formatMessage({
                        id: 'table.footer.rows_text',
                    })}
                    showPaginationBottom={showPaginationBottom}
                    showPageSizeOptions={showPageSizeOptions}
                    onFetchData={(state) => this.fetchStrategy(state)}
                    collapseOnDataChange={false}
                    getTheadProps={() => {
                        if (this.state.headerWidth > 0) {
                            return {
                                style: {
                                    width: this.state.headerWidth,
                                },
                            };
                        }

                        return {
                            style: {},
                        };
                    }}
                    getTdProps={(state, rowInfo, column) => {
                        return {
                            onClick: (e, handleOriginal) => {
                                const clickableColumns = [
                                    'article_nr',
                                    'productName',
                                    'name',
                                    'supplier',
                                ];
                                if (clickableColumns.includes(column.id)) {
                                    /*
                  sometimes rowinfo.original has product id as rowInfo.original.id but sometime as
                  rowInfo.original.product.id, so below is temporary solution
              */
                                    showProductDetails(
                                        rowInfo.original.product
                                            ? rowInfo.original.product.id
                                            : rowInfo.original.id,
                                        deliveryDate
                                    );
                                }

                                if (handleOriginal) {
                                    handleOriginal();
                                }
                            },

                            onMouseOver: () => {
                                if (addHoverClassOnColumn) {
                                    addHoverClassOnColumn(column.id);
                                }
                            },

                            onMouseLeave: () => {
                                if (addHoverClassOnColumn) {
                                    addHoverClassOnColumn(null);
                                }
                            },
                        };
                    }}
                    getThProps={() => {
                        return {
                            onMouseLeave: () => {
                                if (addHoverClassOnColumn) {
                                    addHoverClassOnColumn(null);
                                }
                            },
                        };
                    }}
                    onPageChange={this.scrollToTableTop}
                    onPageSizeChange={this.onPageSizeChange}
                    onFilteredChange={this.onFilteredChange}
                    resolveData={resolveData}
                />
            </div>
        );
    }
}

Table.propTypes = {
    data: PropTypes.array,
    highlight: PropTypes.bool,
    withStickyHeader: PropTypes.bool,
    showPaginationBottom: PropTypes.bool,
    showPageSizeOptions: PropTypes.bool,
    columns: PropTypes.array.isRequired,
    filterable: PropTypes.bool.isRequired,
    hideHead: PropTypes.bool,
    hideBody: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

Table.defaultProps = {
    data: [],
    className: '',
    highlight: true,
    withStickyHeader: true,
    showPaginationBottom: true,
    showPageSizeOptions: true,
    hideHeader: false,
    hideBody: false,
    onClick: () => null,
};

const mapStateToProps = (state) => {
    return {
        filterable: state.table.filterable,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

const mapDispatchToProps = {
    toggleFilterable,
    openFilterable,
    showProductDetails,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Table));

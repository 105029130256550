import React from 'react';
import { callendarGrey } from '../../../../../assets/images/icons';
import { Container } from 'mdbreact';
import DeliveryDatepickerContainer from '../../../../Datepicker/DeliveryDatepickerContainer';
import FormattedMessageWithClass from '../../../../Utils/FormattedMessageWithClass';
import FiltersContainer from '../Filters/FiltersContainer';
import SearchContainer from '../Search/SearchContainer';
import ProductSliderContainer from '../../../../ProductSlider/ProductSliderContainer';

const MasterTableHeader = ({ openDeliveryDatepicker }) => (
    <Container fluid className="full-width">
        <div className="master-table-header">
            <Container>
                <div className="master-table-header__datepicker-wrapper">
                    <FormattedMessageWithClass
                        className="master-table-header__label"
                        id={'table.delivery_datepicker.label'}
                    />
                    <div className="master-table-header__datepicker">
                        <DeliveryDatepickerContainer />
                        <img
                            onClick={openDeliveryDatepicker}
                            src={callendarGrey}
                            className="callendar icon"
                            alt="callendar ico"
                        />
                    </div>
                </div>
                <div>
                    <ProductSliderContainer />
                </div>
                <div>
                    <SearchContainer withoutCategories={true} />
                </div>
                <div>
                    <FiltersContainer />
                </div>
            </Container>
        </div>
    </Container>
);

export default MasterTableHeader;

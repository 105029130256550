import React from 'react';
import Promotions from './Promotions';
import getColumns from '../../../services/table/promotionsColumns';
import { toggleFilterable } from '../../../actions/table';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchPromotions } from '../../../actions/routines';

class PromotionsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: getColumns(),
            whatColumnsToShowForPromotions: localStorage.getItem(
                'whatColumnsToShowForPromotions'
            )
                ? JSON.parse(
                      localStorage.getItem('whatColumnsToShowForPromotions')
                  )
                : getColumns(),
        };
    }

    componentDidMount() {
        const { columns, whatColumnsToShowForPromotions } = this.state;
        const updatedColumns = columns.map((column) => {
            const matchedColumn = whatColumnsToShowForPromotions.find(
                (c) => c.id === column.id
            );
            if (matchedColumn) {
                return { ...column, show: matchedColumn.show };
            }
            return column;
        });
        this.setState({ columns: updatedColumns });
    }

    fetchData = (params) => {
        this.props.fetchPromotions(params);
    };

    handleToggleColumn = (c) => {
        const columns = [];
        columns.push(...this.state.columns);
        columns.forEach((column, index) => {
            if (column.id === c.target.name) {
                columns[index].show = !column.show;
            }
        });

        const columnsObjectsWithBolean = columns.map((el) => ({
            id: el.id,
            show: el.show,
        }));
        localStorage.setItem(
            'whatColumnsToShowForPromotions',
            JSON.stringify(columnsObjectsWithBolean)
        );
        this.setState({
            columns: columns,
            whatColumnsToShowForPromotions: JSON.parse(
                localStorage.getItem('whatColumnsToShowForPromotions')
            ),
        });
    };

    render() {
        const { data, filterable, loading, total, toggleFilterable } =
            this.props;

        return (
            <Promotions
                data={data}
                columns={this.state.columns}
                isFilterable={filterable}
                loading={loading}
                itemsCount={total}
                toggleFilterable={toggleFilterable}
                handleToggleColumn={this.handleToggleColumn}
                handleFetchData={this.fetchData}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        filterable: state.table.filterable,
        data: state.promotions.data,
        total: state.promotions.total,
        loading: state.promotions.loading,
    };
};

const mapDispatchToProps = { toggleFilterable, fetchPromotions };

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(PromotionsContainer)
);

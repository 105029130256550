import { combineReducers } from 'redux';
import { locale } from './locale';
import tableData from './tableData';
import deliveryDatepicker from './deliveryDatepicker';
import security from './security';
import apiErrors from './apiErrors';
import loadingState from './loadingState';
import masterTable from './masterTable';
import table from './table';
import productDetails, { productDetailsModal } from './productDetails';
import userDetails from './userDetails';
import allergens from './allergens';
import {
    createOrUpdateActiveCart,
    activeCart,
    cart,
    deleteCart,
    addItemCart,
    addItemsCart,
    removeItemCart,
    completeCart,
    fairCart,
} from './cart';
import { delivers, deliverySchema } from './delivery';
import promotions from './promotions';
import productTopList from './productTopList';
import {
    notifications,
    readNotifications,
    unreadNotifications,
    unreadNormalNotifications,
    unreadServiceNotifications,
} from './notifications';
import { promotionalModal } from './promotionalModal';
import campaigns from './campaigns';
import recipeCategories from './recipeCategories';
import {
    recipeListByCategory,
    recipeDetails,
    favouriteRecipesList,
    recipeSearch,
} from './recipe';
import {
    downloadCenterFolders,
    downloadCenterFiles,
    downloadCenter,
    downloadCenterFilesSearch,
} from './downloadCenter';
import productCategories from './productCategories';
import productOrderHistory from './productOrderHistory';
import fairDeliveryDates from './fairDeliveryDates';
import { fairDynamicWeeks, addFairDynamicWeek } from './fairDynamicWeeks';
import fairProducts from './fairProducts';
import fairSuppliers from './fairSuppliers';
import fairPoints from './fairPoints';
import { completeFairCart } from './fairCart';
import retour from './retour';
import { noveltiesProducts } from './novelty';

export default combineReducers({
    locale,
    tableData,
    deliveryDatepicker,
    security,
    apiErrors,
    loadingState,
    masterTable,
    table,
    productDetails,
    userDetails,
    allergens,
    cart,
    activeCart,
    createOrUpdateActiveCart,
    deleteCart,
    addItemCart,
    addItemsCart,
    removeItemCart,
    deliverySchema,
    delivers,
    promotions,
    productTopList,
    notifications,
    readNotifications,
    unreadNotifications,
    unreadNormalNotifications,
    unreadServiceNotifications,
    promotionalModal,
    campaigns,
    completeCart,
    recipeCategories,
    recipeListByCategory,
    recipeSearch,
    recipeDetails,
    downloadCenterFolders,
    downloadCenterFiles,
    downloadCenter,
    downloadCenterFilesSearch,
    favouriteRecipesList,
    productCategories,
    productOrderHistory,
    productDetailsModal,
    retour,
    fairDeliveryDates,
    fairDynamicWeeks,
    fairProducts,
    fairSuppliers,
    addFairDynamicWeek,
    fairCart,
    completeFairCart,
    fairPoints,
    noveltiesProducts,
});

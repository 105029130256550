import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import Points from '../Pages/Campaign/components/Points';

class CampaignSticker extends React.Component {
    constructor(props) {
        super(props);

        const c = this.props.campaign;
        this.state = {
            name:
                c && c.campaign_prices.length > 0
                    ? c.campaign_prices[0].name
                    : 0,
            newPoints: 0,
            userPoints:
                c && c.campaign_points.length > 0
                    ? c.campaign_points[0].points +
                      c.campaign_points[0].open_points
                    : 0,
            campaignPrice:
                c && c.campaign_prices.length > 0
                    ? c.campaign_prices[0].points
                    : '',
        };
    }

    componentDidMount() {
        if (!R.isEmpty(this.props.cart)) {
            this.calculateProperPoints();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { cart, campaign } = this.props;

        //Calculate proper points based on number of products added to cart
        if (!R.equals(prevProps.cart, cart) && campaign) {
            this.calculateProperPoints(prevState.name);
        }
    }

    calculateProperPoints = (name = '') => {
        const c = this.props.campaign;
        if (!c) {
            return;
        }

        const newPoints = this.calculateNewPoints();
        const userPoints =
            c.campaign_points.length > 0
                ? c.campaign_points[0].points + c.campaign_points[0].open_points
                : 0;
        const totalPoints = userPoints + newPoints;

        const campaignPrice = this.findCampaignPriceAndMaxByPoints(totalPoints);

        this.setState({
            name: campaignPrice.found ? campaignPrice.found.name : name,
            newPoints: newPoints,
            userPoints: totalPoints,
            campaignPrice: campaignPrice.found
                ? campaignPrice.found.points
                : '',
        });
    };

    findCampaignPriceAndMaxByPoints = (points) => {
        const campaign = this.props.campaign;

        if (campaign.campaign_prices.length === 0) {
            return 0;
        }

        const where = R.where({
            points: R.gt(R.__, points),
        });

        return {
            found: R.find(where)(campaign.campaign_prices),
            max: Math.max(...campaign.campaign_prices.map((o) => o.points), 0),
        };
    };

    calculateNewPoints = () => {
        const { cart, campaign } = this.props;

        if (R.isEmpty(cart) || R.isEmpty(cart.basket_items)) {
            return 0;
        }

        let points = 0;
        cart.basket_items.forEach((product) => {
            const p = product.campaign_new_points[campaign.id];

            if (p) {
                points += p;
            }
        });

        return points;
    };

    render() {
        const { campaign } = this.props;
        const { userPoints } = this.state;

        //Do not display badge when there is no campaign products or calculation based on product quantity is not finished
        if (!campaign || !campaign.is_active) {
            return '';
        }

        return (
            <Points
                color={campaign.color ? campaign.color : '#000'}
                points={userPoints ? userPoints : 0}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.data,
    };
};

export default connect(mapStateToProps, null)(CampaignSticker);

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import ItemVariant from './ItemVariant';
import PointsWithoutLink from '../PointsWithoutLink';
import { Link } from 'react-router-dom';
import moment from 'moment';
import FormattedMessageWithClass from '../../../../Utils/FormattedMessageWithClass';

const Item = ({
    id,
    name,
    image,
    pdf,
    activeFrom,
    activeTo,
    variants,
    description,
    userPoints,
    color,
}) => {
    return (
        <div className="campaign__item">
            <Row>
                <Col>
                    <div
                        className="campaign__item__header"
                        style={{ backgroundColor: color }}
                    >
                        {name}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm="4" className="campaign__item__left-column">
                    {image && (
                        <div className="campaign__item__image-wrapper">
                            <img src={image} alt={`${name}`} />
                        </div>
                    )}
                    {pdf && (
                        <div className="campaign__item__btn-wrapper">
                            <a
                                href={pdf}
                                className="campaign__item__download-pdf btn-red btn-pdf"
                            >
                                <FormattedMessage id="btn.pdf" />
                            </a>
                        </div>
                    )}
                </Col>
                <Col sm="5" className="campaign__item__right-column">
                    <p className="campaign__item__active-date">
                        <FormattedMessage
                            id="campaign.active_date"
                            values={{
                                activeFrom:
                                    moment(activeFrom).format('DD/MM/YYYY'),
                                activeTo: moment(activeTo).format('DD/MM/YYYY'),
                            }}
                        />
                    </p>
                    {variants.map((variant) => (
                        <ItemVariant
                            key={id}
                            id={id}
                            points={variant.points}
                            name={variant.name}
                            color={color}
                        />
                    ))}
                    <p className="campaign__item__description">{description}</p>

                    <Link
                        to={{
                            pathname: `/products`,
                            search: `?page=1&filtered=1&c_campaigns[]=${id}`,
                            state: { searchCampaign: true },
                        }}
                    >
                        <Button className="btn-grey">
                            <FormattedMessage id="campaign.see_product_btn" />
                        </Button>
                    </Link>
                </Col>
                <Col sm="3">
                    <PointsWithoutLink color={color} points={userPoints} />

                    <FormattedMessageWithClass
                        id="campaign.points_add_remark"
                        className="campaign__item__remark"
                    />
                </Col>
            </Row>
        </div>
    );
};

Item.propTypes = {
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    activeFrom: PropTypes.string.isRequired,
    activeTo: PropTypes.string.isRequired,
    variants: PropTypes.array.isRequired,
    description: PropTypes.string.isRequired,
    userPoints: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
};

export default Item;

import PropTypes from 'prop-types'
import React from "react";
import {Chip} from "mdbreact";
import SelectedFilterLabel from "./SelectedFilterLabel";

const SelectedFilter = ({filter, handleRemoveFilter}) => {
    return (
        <Chip>
            <SelectedFilterLabel filter={filter}/>
            <span
                onClick={handleRemoveFilter}
                data-id={filter.id}
                data-type={filter.type}
                data-name={filter.name}
                className="filters__remove-filter"
            />
        </Chip>
    )
};

SelectedFilter.propTypes = {
  filter: PropTypes.object.isRequired
};

export default SelectedFilter;

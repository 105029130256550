import PropTypes from 'prop-types';
import React from 'react';
import TitleHeader from '../components/TitleHeader';
import { deliveryGrey } from '../../../assets/images/icons';
import Table from '../../Table/Table';
import DeliveryDetails from './components/DeliveryDetails';
import SearchContainer from './components/SearchContainer';
import { FormattedMessage, injectIntl } from 'react-intl';
import Currency from '../../Utils/Currency';
import DownloadDocumentsContainer from './components/DownloadDocuments/DownloadDocumentsContainer';
import moment from 'moment';
import PopoverBadge from '../../Table/components/PopoverBadge';
import { Badge } from 'mdbreact';
import orderStatus from '../../../const/orderStatus.json';
import { FormattedHtmlMessage } from '../../Utils/FormattedHtmlMessage';

const Delivery = ({
    inProgressDelivers,
    closedDelivers,
    inProgressDeliversCount,
    closedDeliversCount,
    loading,
    sendRetourForm,
    retour,
    resetRetourError,
    onHoldDelivers,
    onHoldDeliversCount,
    intl,
    orderStatuses,
    handleUpdateOrderStatus,
    transportCostAmount,
}) => {
    const getColumns = (status, orderStatuses) => [
        {
            id: 'order_date',
            Header: <FormattedMessage id="table.header.order_date" />,
            accessor: 'order_date',
            width: 110,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__order-date',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__order-date',
            Cell: (row) => moment(row.value).format('DD/MM/YYYY'),
        },
        {
            id: 'order_nr',
            Header: <FormattedMessage id="table.header.order_number" />,
            accessor: 'order_nr',
            width: 90,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__order-number',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__order-number',
        },
        {
            id: 'delivery_date',
            Header: <FormattedMessage id="table.header.delivery_date" />,
            accessor: 'delivery_date',
            width: 120,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__delivery-date',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__delivery-date',
            Cell: (row) => row.value && moment(row.value).format('DD/MM/YYYY'),
        },
        {
            id: 'status',
            Header: <FormattedMessage id="table.header.status" />,
            accessor: 'status',
            width: 180,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__message',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__message',
            Cell: (row) => {
                return (
                    <div className="delivery__status">
                        <div
                            className={`delivery__status--${
                                row.original.delivery_date ? status : 'pending'
                            }`}
                        />
                        <FormattedMessage
                            id={`delivery.status.${
                                row.original.delivery_date ? status : 'pending'
                            }`}
                        />
                    </div>
                );
            },
        },
        {
            id: 'order_reference',
            Header: <FormattedMessage id="table.header.reference" />,
            accessor: 'order_reference',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__reference',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__reference',
        },
        {
            id: 'customer_order_number',
            Header: <FormattedMessage id="table.header.your_order_number" />,
            accessor: 'customer_order_number',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__customer-order-number',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__customer-order-number',
        },
        {
            id: 'icons',
            Header: '',
            accessor: 'icons',
            width: 40,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__icons disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__icons disable-sort-indicator',
            sortable: false,
            Cell: (row) =>
                orderStatuses[row.original.id] === orderStatus.RETOUR && (
                    <Badge className="badge--circle badge--red">R</Badge>
                ),
        },
        {
            id: 'documents',
            Header: <FormattedMessage id="table.header.documents" />,
            accessor: 'documents2',
            width: 240,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__documents',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__documents',
            Cell: (row) => (
                <DownloadDocumentsContainer
                    orderNumber={row.original.order_nr}
                />
            ),
        },
    ];

    /**
     * Filter order items on status
     *
     * @param {array} orderItems
     * @param {string} status
     *
     * @returns {array}
     */
    const filterOnStatus = (orderItems, status) => {
        return orderItems.filter(
            (orderHistoryItem) =>
                orderHistoryItem.hasOwnProperty('status') &&
                orderHistoryItem.status === status
        );
    };

    /**
     * Filter order items by excluding the given status
     *
     * @param {array} orderItems
     * @param {string} status
     *
     * @returns {array}
     */
    const filterOnExcludeStatus = (orderItems, status) => {
        return orderItems.filter(
            (orderHistoryItem) =>
                !orderHistoryItem.hasOwnProperty('status') ||
                orderHistoryItem.status !== status
        );
    };

    return (
        <div className="delivery">
            <TitleHeader icon={deliveryGrey} title="delivery.title">
                <SearchContainer />
            </TitleHeader>
            {onHoldDeliversCount > 0 && (
                <div className="delivery__table">
                    <div className="delivery__table-title__wrapper--with-badge">
                        <h2 className="delivery__table-title">
                            <FormattedMessage id="delivery.on_hold_title" />
                        </h2>
                        <PopoverBadge
                            id={0}
                            type="information"
                            badgeText="i"
                            backgroundColor={'#4682b4'}
                            fontColor="white"
                            popoverHeader={intl.formatMessage({
                                id: 'delivery.on_hold_info.title',
                            })}
                            popoverBodyComponent={
                                <FormattedHtmlMessage
                                    id="delivery.on_hold_info.body"
                                    values={{
                                        amount: (
                                            <Currency
                                                value={transportCostAmount}
                                            />
                                        ),
                                    }}
                                />
                            }
                            wrapperClassName="on-hold-information-badge-wrapper"
                            placement="right"
                        />
                    </div>
                    <Table
                        columns={getColumns('on_hold', orderStatuses)}
                        data={onHoldDelivers}
                        withStickyHeader={false}
                        pageSize={onHoldDeliversCount}
                        itemsCount={onHoldDeliversCount}
                        showPagination={false}
                        SubComponent={(row) => {
                            if (row.original.order_history_items.length === 0) {
                                return (
                                    <p className="text-center my-4">
                                        <FormattedMessage id="delivery.no_items" />
                                    </p>
                                );
                            }
                            return (
                                <DeliveryDetails
                                    products={filterOnStatus(
                                        row.original.order_history_items,
                                        'HOLD'
                                    )}
                                    invoice={row.original.invoice}
                                    title="delivery.details.on_hold"
                                    orderNumber={row.original.order_nr}
                                    handleUpdateOrderStatus={
                                        handleUpdateOrderStatus
                                    }
                                />
                            );
                        }}
                        filterable={false}
                        loading={loading}
                    />
                </div>
            )}
            {inProgressDeliversCount > 0 && (
                <div className="delivery__table">
                    <h2 className="delivery__table-title">
                        <FormattedMessage id="delivery.in_progress_title" />
                    </h2>
                    <Table
                        columns={getColumns('delivered_open', orderStatuses)}
                        data={inProgressDelivers}
                        withStickyHeader={false}
                        pageSize={inProgressDeliversCount}
                        itemsCount={inProgressDeliversCount}
                        showPagination={false}
                        SubComponent={(row) => {
                            if (row.original.order_history_items.length === 0) {
                                return (
                                    <p className="text-center my-4">
                                        <FormattedMessage id="delivery.no_items" />
                                    </p>
                                );
                            }
                            return (
                                <DeliveryDetails
                                    products={filterOnExcludeStatus(
                                        row.original.order_history_items,
                                        'HOLD'
                                    )}
                                    invoice={row.original.invoice}
                                    deliveryAt={row.original.delivery_date}
                                    title="delivery.details.open"
                                    sendRetourForm={sendRetourForm}
                                    retour={retour}
                                    resetRetourError={resetRetourError}
                                    orderNumber={row.original.order_nr}
                                    handleUpdateOrderStatus={
                                        handleUpdateOrderStatus
                                    }
                                />
                            );
                        }}
                        filterable={false}
                        loading={loading}
                    />
                </div>
            )}
            {closedDeliversCount > 0 && (
                <div className="delivery__table">
                    <h2 className="delivery__table-title">
                        <FormattedMessage id="delivery.closed_title" />
                    </h2>
                    <Table
                        columns={getColumns('delivered', orderStatuses)}
                        data={closedDelivers}
                        withStickyHeader={false}
                        pageSize={
                            closedDeliversCount < 10 ? closedDeliversCount : 10
                        }
                        itemsCount={closedDeliversCount}
                        SubComponent={(row) => {
                            if (row.original.order_history_items.length === 0) {
                                return (
                                    <p className="text-center my-4">
                                        <FormattedMessage id="delivery.no_items" />
                                    </p>
                                );
                            }
                            return (
                                <DeliveryDetails
                                    products={filterOnExcludeStatus(
                                        row.original.order_history_items,
                                        'HOLD'
                                    )}
                                    invoice={row.original.invoice}
                                    deliveryAt={row.original.delivery_date}
                                    title="delivery.details.delivered"
                                    sendRetourForm={sendRetourForm}
                                    retour={retour}
                                    resetRetourError={resetRetourError}
                                    orderNumber={row.original.order_nr}
                                    handleUpdateOrderStatus={
                                        handleUpdateOrderStatus
                                    }
                                />
                            );
                        }}
                        filterable={false}
                        loading={loading}
                    />
                </div>
            )}
        </div>
    );
};

Delivery.propTypes = {
    closedDelivers: PropTypes.array.isRequired,
    inProgressDelivers: PropTypes.array.isRequired,
    inProgressDeliversCount: PropTypes.number.isRequired,
    onHoldDelivers: PropTypes.array.isRequired,
    onHoldDeliversCount: PropTypes.number.isRequired,
};

export default injectIntl(Delivery);

import React from "react";
import PropTypes from "prop-types";
import WeekColumn from "./WeekColumn";
import FutureDeliveries from "./FutureDeliveries";

const OrdersHistory = ({weeks, inDelivery, rowIndex, providerError}) => {
    if (providerError) {
        return rowIndex === 0 ? "History orders could not be retrieved." : null;
    }

    return (
        <div className="d-flex flex-row-reverse">
            <div className="flex-center">
                {weeks.map(weekData => (
                    <WeekColumn
                        key={ weekData.name }
                        week_name={ weekData.name }
                        quantity={ weekData.quantity }
                        is_current={ weekData.isCurrent }
                    />
                ))}
            </div>
            <FutureDeliveries inDelivery={inDelivery} />
        </div>
    );
}

OrdersHistory.propTypes = {
    weeks: PropTypes.array,
    inDelivery: PropTypes.array
};

export default OrdersHistory;

import React from 'react';
import 'react-table/react-table.css';
import CartPage from './CartPage';
import { connect } from 'react-redux';
import {
    fetchActiveCartData,
    fetchFairCarts,
    fetchUserData,
    removeItemCartPromiseCreator,
} from '../../../actions/routines';
import * as R from 'ramda';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import FairService from '../../../services/api/Fair';
import { Context } from '../../context/context';
import PropTypes from 'prop-types';

class CartPageContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            cartList: [],
            fairCart: [],
            fairCartProducts: [],
            fairProducts: [],
            draftCartList: [],
            loadingState: {},
        };
    }

    componentDidUpdate(prevProps) {
        const { cart, fairCart } = this.props;
        if (
            !R.isEmpty(cart) &&
            (R.isEmpty(this.state.cartList) || !R.equals(prevProps.cart, cart))
        ) {
            const cartList = [];
            cartList.push(this.mergeAmountToProduct(cart));
            this.setState({
                cartList,
                draftCartList: cart.basket_items,
            });
        }

        if (
            !R.isEmpty(fairCart) &&
            (R.isEmpty(this.state.fairCart) ||
                !R.equals(prevProps.fairCart, fairCart))
        ) {
            this.getFairProductList();
        }
    }

    setFairCartProducts = (newState) => {
        this.setState({ fairCartProducts: newState });
    };

    componentDidMount() {
        this.props.fetchUserData();
        this.props.fetchActiveCartData();
        this.props.fetchFairCarts();
        FairService.getFairProductsList().then((response) => {
            this.setState({ fairCartProducts: response.data });
        });
        FairService.getProducts({
            mylist: 0,
            s_s_supplier: 'all-suppliers',
            for_cart: true,
        }).then((response) => this.setState({ fairProducts: response.data }));
    }

    addProduct = async (week, productId, amount) => {
        const loadStateKey = `${productId}-${week}`;

        this.setState((prevState) => ({
            loadingState: { ...prevState.loadingState, [loadStateKey]: true },
        }));

        await FairService.addProduct(
            week,
            productId,
            amount === '' ? 0 : amount
        );

        await FairService.getProducts({
            mylist: 0,
            s_s_supplier: 'all-suppliers',
            for_cart: true,
        }).then((response) => {
            this.setState((prevState) => ({
                fairProducts: response.data,
                loadingState: {
                    ...prevState.loadingState,
                    [loadStateKey]: false,
                },
            }));
        });
    };

    mergeAmountToProduct = (cart) => {
        const mergedProducts = [];
        cart.basket_items &&
            cart.basket_items.forEach((product) => {
                mergedProducts.push({
                    ...product,
                    amount: product.amount,
                    campaign_new_points: product.campaign_new_points,
                });
            });

        cart.basket_items = mergedProducts;

        return cart;
    };

    getFairProductList = () => {
        const { fairCart } = this.props;

        const productList = [];

        if (Array.isArray(fairCart)) {
            fairCart.forEach((cart) => {
                cart.basket_items.forEach((product) => {
                    productList.push({ ...product.product });
                });
            });
        } else if (!R.empty(fairCart)) {
            fairCart.basket_items.forEach((product) => {
                productList.push({ ...product.product });
            });
        }

        productList.length > 0 &&
            this.setState({
                fairCart: R.uniq(productList),
            });
    };

    handleRemoveProduct = (cartId, productId) => {
        this.props
            .removeItemCartPromiseCreator({ productId, cartId })
            .then(() => {
                const { cartList } = this.state;
                const newData = [];

                for (let i = 0; i < cartList.length; i++) {
                    if (cartList[i].id === cartId) {
                        let d = cartList[i].basket_items.filter((cardData) => {
                            return cardData.product.id !== productId;
                        });

                        cartList[i].basket_items = d;
                    }

                    newData.push(cartList[i]);
                }

                const updatedDraftCartList = this.state.draftCartList.map(
                    (product) => {
                        if (product.product.id === productId) {
                            return { ...product, amount: 0 };
                        }
                        return product;
                    }
                );

                this.setState({
                    cartList: newData,
                    draftCartList: updatedDraftCartList,
                });
            });
    };

    handleCartRemove = (id) => {
        const { cartList } = this.state;

        this.setState({ cartList: cartList.filter((cart) => cart.id !== id) });
    };

    draftCartListChange = (obj) => {
        this.setState({ draftCartList: obj });
    };

    render() {
        const { cartList, draftCartList } = this.state;
        const { userDetails, fairCart, fetchingActiveLoading } = this.props;
        return (
            <Context.Provider
                value={{
                    draftCartList: draftCartList,
                    draftCartListChange: this.draftCartListChange,
                    cartList: cartList,
                }}
            >
                <CartPage
                    cartList={cartList}
                    fairCart={fairCart}
                    fairCartProducts={this.state.fairCartProducts}
                    handleRemoveProduct={this.handleRemoveProduct}
                    handleCartRemove={this.handleCartRemove}
                    loading={fetchingActiveLoading}
                    isFairActive={userDetails && userDetails.is_fair_active}
                    fairData={this.state.fairProducts}
                    setFairCartProducts={this.setFairCartProducts}
                    addProduct={this.addProduct}
                    loadingState={this.state.loadingState}
                />
            </Context.Provider>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart.data,
        fairCart: state.fairCart.data,
        fetchingActiveLoading: state.activeCart.loading,
        userDetails: state.userDetails.data,
        fairData: state.fairProducts.data,
    };
};

CartPageContainer.propTypes = {
    fetchActiveCartData: PropTypes.func,
    fetchFairCarts: PropTypes.func,
    fetchUserData: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindPromiseCreators(
            {
                removeItemCartPromiseCreator,
            },
            dispatch
        ),
        ...bindActionCreators(
            { fetchActiveCartData, fetchFairCarts, fetchUserData },
            dispatch
        ),
    };
};

CartPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CartPageContainer);

export default CartPageContainer;

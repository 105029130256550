import React from 'react';
import PropTypes from 'prop-types';
import ProductDetails from "./ProductDetail";
import {fetchProductData} from "../../actions/routines";
import {connect} from "react-redux";
import {Modal, ModalBody} from "mdbreact";
import Spinner from "../Utils/Spinner";
import Products from "../../services/api/Products";
import saveFile from "../../services/fileSaver";
import {toast} from "react-toastify";
import {injectIntl} from "react-intl";
import {hideProductDetails} from "../../actions/productDetails";
import * as R from 'ramda';

class ProductDetailsContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inAssortment: null
        }
    }

    componentDidUpdate(prevProps) {
        const {id, isOpen, deliveryDate, product} = this.props;

        if (!prevProps.isOpen && isOpen && id) {
            this.props.fetchProductData({id, deliveryDate});
        }

        if (!R.equals(prevProps.product, product)) {
            this.setState({
                inAssortment: product.in_assortment
            })
        }
    }

    handleDownloadProductSpecificationsPdf = () => {
        const {intl} = this.props;

        Products.getProductSpecificationPdf(this.props.product.id).then((response) => {
            if (response.status === 204) {
                toast.error(intl.formatMessage({id: "api.error.file_empty_content"}));

                return;
            }

            saveFile(response, intl.formatMessage({id: "product_details.downloads.sheet_file_title"}));
        })
    };

    handleRemoveFromAssortment = () => {
        this.setState({
            inAssortment: false
        })
    };

    render() {
        const {product, isOpen, hideProductDetails, loading} = this.props;

        return (
           <React.Fragment>
               <Modal isOpen={isOpen} size="lg" className="product-details" backdropClassName="product-details__backdrop" toggle={hideProductDetails}>
                   <ModalBody className={loading ? 'modal-body--loading': ''}>
                       {loading ?
                           <Spinner className="spinner--brand" width={50} height={50}/>
                           :
                           <ProductDetails
                               product={product}
                               isOpen={isOpen}
                               closeModal={hideProductDetails}
                               inAssortment={this.state.inAssortment}
                               handleDownloadProductSpecificationsPdf={this.handleDownloadProductSpecificationsPdf}
                               handleRemoveFromAssortment={this.handleRemoveFromAssortment}
                           />
                       }
                   </ModalBody>
               </Modal>
           </React.Fragment>
        )

    }
}


ProductDetailsContainer.propTypes = {
  fetchProductData: PropTypes.func,
  hideProductDetails: PropTypes.func,
  id: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  product: PropTypes.object
};

const mapStateToProps = (state) => {
    return {
        product: state.productDetails.data,
        loading: state.productDetails.loading,
        isOpen: state.productDetailsModal.isOpen,
        id: state.productDetailsModal.id,
        deliveryDate: state.deliveryDatepicker.deliveryDate
    }
};

const mapDispatchToProps = {
    fetchProductData, hideProductDetails
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ProductDetailsContainer));

import React from 'react';
import promotionsGrey from '../../../assets/images/promotions-grey.svg';
import TitleHeader from '../components/TitleHeader';
import SearchContainer from './components/SearchContainer';
import TableHeader from '../../Table/components/TableHeader';
import Table from '../../Table/Table';

const Promotions = ({
    data,
    columns,
    loading,
    itemsCount,
    isFilterable,
    toggleFilterable,
    handleToggleColumn,
    handleFetchData,
}) => {
    return (
        <div className="promotions">
            <TitleHeader icon={promotionsGrey} title="promotions.title">
                <SearchContainer />
            </TitleHeader>
            <TableHeader
                columns={columns}
                toggleFilterable={toggleFilterable}
                handleToggleColumn={handleToggleColumn}
                downloadPdfLabel="promotions.download_pdf_label"
                allItemsLabel="promotions.all_products_label"
            />
            <Table
                data={data}
                columns={columns}
                filterable={isFilterable}
                loading={loading}
                manual
                onFetchData={(state) => handleFetchData(state)}
                itemsCount={itemsCount}
                handleToggleColumn={handleToggleColumn}
            />
        </div>
    );
};

export default Promotions;

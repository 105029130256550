import React, {Component} from 'react';
import { globeGrey, globeWhite } from '../../assets/images/icons';
import PropTypes from 'prop-types';
import LanguageSwitcherContainer from "../LanguageSwitcher/LanguageSwitcherContainer";

class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isDisclaimerOpen: false
        }
    }

    render() {
        const {inverted} = this.props;
        const email = 'info@franky.be';

        return (
            <footer className={`footer ${inverted ? 'footer--inverted' : ''}`}>
                <p className="footer__contact-info">WAREGEMSEWEG 28 | 9790 WORTEGEM-PETEGEM | T 056 68 95 75 | F 056 68
                    76 23 | <a href={`mainto:${email}`}>{email.toLocaleUpperCase()}</a></p>
                <img className="footer__icon" src={inverted ? globeWhite : globeGrey} alt="globe icon"/>
                <LanguageSwitcherContainer variant="footer"/>
            </footer>
        );
    }
}

Footer.propTypes = {
    inverted: PropTypes.bool
};

Footer.defaultProps = {
    inverted: false
};

export default Footer;

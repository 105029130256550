import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'mdbreact';
import QuantityContainer from '../../Quantity/QuantityContainer';
import Promotions from '../../ProductDetails/components/Promotions';
import Campaigns from '../../ProductDetails/components/Campaigns';
import Specification from '../../ProductDetails/components/Specification';

const PromoBox = ({ product, style }) => {
    const {
        id,
        name: title,
        description,
        items_in_package: itemsInPackage,
        order_as_package: orderAsPackage,
        images,
        amount,
        article_nr: articleNr,
    } = product;

    const image = images.length > 0 ? images[0] : {};

    return (
        <div className="promo-box" style={style}>
            <Row>
                <Col size="12">
                    <p className="promo-box__title">{title}</p>
                    <hr />
                </Col>
            </Row>
            <Row>
                <Col size="6">
                    {articleNr && (
                        <Specification
                            label="product_details.specifications.article_nr"
                            value={articleNr}
                        />
                    )}
                    <img
                        src={image.url}
                        alt={`${title}`}
                        className="img-fluid"
                    />
                </Col>

                <Col size="6">
                    <Row className="align-items-center">
                        <Col size="5">
                            <div className="promo-box__body">
                                <QuantityContainer
                                    product={product}
                                    productId={id}
                                    quantity={amount ? amount : 0}
                                    tabIndex={id}
                                    itemsInPackage={itemsInPackage}
                                    orderAsPackage={orderAsPackage}
                                />
                            </div>
                        </Col>
                        <Col size="7">
                            {(product.promotions.length > 0 ||
                                product.campaigns.length > 0) && (
                                <Row>
                                    <Col className="product-details__promotion-campaign mb-0">
                                        {product.promotions.length > 0 && (
                                            <Promotions
                                                promotions={product.promotions}
                                            />
                                        )}
                                        {product.campaigns.length > 0 && (
                                            <Campaigns
                                                campaigns={product.campaigns}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col size="12">
                            <div className="promo-box__body mt-4">
                                <p className="promo-box__description">
                                    {description}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

PromoBox.propTypes = {
    description: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    image: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
};

export default PromoBox;

import React from 'react';
import PropTypes from "prop-types";
import Footer from "../../../Footer/Footer";
import {Col, Container, Input, Row} from "mdbreact";
import {FormattedMessage} from "react-intl";
import PasswordField from "../PasswordField";
import {Link} from "react-router-dom";
import Header from "../Header";
import ButtonWithSpinner from "../../../Utils/ButtonWithSpinner";

const Login = ({login, password, error, loading, onChange, handleLogin}) => {
    return (
            <div className="security login container-fluid">
                <Header/>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md="4">
                            <form className="form--white-text" onSubmit={handleLogin}>
                                <Input
                                    value={login}
                                    onChange={onChange}
                                    name="login"
                                    label={<FormattedMessage id="security.login.login"/>}
                                    group
                                    type="text"
                                    validate
                                    error="wrong"
                                    success="right"
                                    required
                                />
                                <PasswordField
                                    value={password}
                                    onChange={onChange}
                                    name="password"
                                    label={'security.login.password'}
                                />
                                <div className="login__sign-up">
                                    {error &&
                                    <p className="inline-message__error">{error}</p>
                                    }
                                    <ButtonWithSpinner
                                        label="security.login.sign_up"
                                        isLoading={loading}
                                        type="submit"
                                        className="btn-brand"
                                    />
                                </div>
                                <Link className="security__url" to="/security/password-recovery/request"><FormattedMessage id="security.login.forgot_password"/></Link>
                            </form>
                        </Col>
                    </Row>
                </Container>
                <Footer inverted/>
            </div>
    );
};

Login.propTypes = {
    login: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    loading: PropTypes.bool
};

export default Login;

import PropTypes from 'prop-types'
import React from "react";
import ImagePromotion from "./components/ImagePromotion";
import {Modal, ModalBody} from "mdbreact";
import FormattedMessageWithClass from "../Utils/FormattedMessageWithClass";
import BoxesPromotion from "./components/BoxesPromotion";
import CarouselPromotion from "./components/CarouselPromotion";

const PromotionalModal = ({promotions, isOpen, closeModal}) => {
    const modalVariantClass =  promotions.length > 1 ? '--carousel' : '--single' ;
    const modalTypeClass = promotions.length === 1 && promotions[0].type === 'simple' ? '--simple' : '--boxes';

    return (
        <Modal className={`promotional-modal promotional-modal${modalTypeClass} promotional-modal${modalVariantClass}`} isOpen={isOpen} toggle={closeModal}>
            <span onClick={closeModal} className="modal-close"/>
            <ModalBody>

                {promotions.length > 1 &&
                <CarouselPromotion promotions={promotions}/>
                }

                {promotions.length === 1 && promotions[0].type === 0 &&
                <ImagePromotion data={promotions[0]}/>
                }

                {promotions.length === 1 && promotions[0].type === 1 &&
                <BoxesPromotion items={promotions[0].products}/>
                }
            </ModalBody>
            <span onClick={closeModal}>
                <FormattedMessageWithClass className="promotional-modal__close-footer" id="promotional_modal.close"/>
            </span>
        </Modal>
    );
};

PromotionalModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  promotions: PropTypes.array.isRequired
};

export default PromotionalModal;

import PropTypes from 'prop-types'
import React, {Component} from "react";

class FavouriteIcon extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFavourite: this.props.isFavourite
        }
    }

    toggleFavourite = () => {
        this.setState(prevState => {
            return {isFavourite: !prevState.isFavourite}
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.isFavourite !== this.props.isFavourite) {
            this.setState({
                isFavourite: this.props.isFavourite
            })
        }
    }

    render() {
        const {onClick} = this.props;

        return (
            <div className="favourite-icon">
                <i onClick={() => {
                    if (onClick) {
                        onClick();
                    }

                    this.toggleFavourite();
                }}
                   className={`favourite-icon__icon ${this.state.isFavourite ? 'favourite-icon--is-favourite' : ''}`}
                />
            </div>
        );
    }
}

FavouriteIcon.propTypes = {
    handleClick: PropTypes.func,
    isFavourite: PropTypes.bool
};

export default FavouriteIcon;

import React from 'react';
import { FormattedMessage } from 'react-intl';
import QuantityContainer from '../../components/Quantity/QuantityContainer';
import MyOrderListContainer from './components/MyOrderList/MyOrderListContainer';
import PromotionTooltip from '../PromotionTooltip/PromotionTooltip';
import CampaignPointsBadgeContainer from './components/CampaignPointsBadge/CampaignPointsBadgeContainer';
import OrdersHistory from './components/OrdersHistory/OrdersHistory';

const getColumns = (hideByDefault, deliveryDate, doNotAdd) => {
    let windowWidth = window.innerWidth;
    let width = 'auto';
    let nameWidth = 255;
    let supplierWidth = 150;

    if (windowWidth > 991) {
        width = 'auto';
        supplierWidth = 100;
    } else if (windowWidth >= 768) {
        nameWidth = 100;
        supplierWidth = 90;
    }

    let columns = [
        {
            id: 'article_nr',
            Header: <FormattedMessage id="table.header.product_no" />,
            preferencesLabel: <FormattedMessage id="table.preferences.id" />,
            filterPlaceholder: 'table.filters.input.article_nr',
            accessor: 'article_nr',
            width: 85,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__id',
            className: 'table__rt__col table__rt__col--fix-width table__rt__id',
            show: true,
            Cell: (row) => (row.value ? row.value : ''),
        },
        {
            id: 'name',
            Header: <FormattedMessage id="table.header.product_name" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.product_name" />
            ),
            filterPlaceholder: 'table.filters.input.product_name',
            accessor: 'name',
            width: 'auto',
            style: { overflow: 'visible', whiteSpace: 'normal' },
            headerClassName: 'table__rt__col table__rt__product-name',
            className: 'table__rt__col table__rt__product-name',
            show: true,
        },
        {
            id: 'supplier',
            Header: <FormattedMessage id="table.header.supplier" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.supplier" />
            ),
            filterPlaceholder: 'table.filters.input.supplier',
            accessor: 'supplier',
            width: 'auto',
            style: { whiteSpace: 'normal' },
            className: 'table__rt__col table__rt__supplier',
            show: true,
        },
        {
            id: 'availableFrom',
            Header: <FormattedMessage id="table.header.available" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.available" />
            ),
            accessor: 'availableFrom',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__available disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__available disable-sort-indicator',
            show: true,
            style: { whiteSpace: 'normal' },
            width: 'auto',
            filterable: false,
            sortable: false,
        },
        {
            id: 'promotion',
            Header: <FormattedMessage id="table.header.promotion" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.promotion" />
            ),
            accessor: 'promotion',
            filterable: false,
            headerClassName:
                'table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__promotion disable-sort-indicator',
            show: true,
            width: 'auto',
            sortable: false,
            Cell: (row) => {
                if (
                    row.original.promotions &&
                    row.original.promotions.length > 0
                ) {
                    return (
                        <PromotionTooltip
                            id={row.original.id}
                            promotions={row.original.promotions}
                        />
                    );
                }
            },
        },
        {
            id: 'price',
            Header: <FormattedMessage id="table.header.price" />,
            preferencesLabel: <FormattedMessage id="table.preferences.price" />,
            accessor: 'prices',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__available',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__available',
            show: true,
            filterable: false,
            width: 'auto',
            Cell: (row) =>
                row.original.price && <span>{row.original.price.label}</span>,
        },
        {
            id: 'weight',
            Header: <FormattedMessage id="table.header.package_size" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.package_size" />
            ),
            accessor: 'weight',
            filterable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__package-size disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__package-size disable-sort-indicator',
            show: true,
            width: 'auto',
            sortable: false,
        },
        {
            id: 'quantity',
            Header: <FormattedMessage id="table.header.quantity" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.quantity" />
            ),
            accessor: 'quantity',
            filterable: false,
            width: 'auto',
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__quantity disable-sort-indicator',
            show: true,
            sortable: false,
            Cell: (row) => {
                const product = row.original;
                return (
                    <QuantityContainer
                        product={product}
                        productId={product.id}
                        quantity={product.amount ? product.amount : 0}
                        tabIndex={row.viewIndex + 1}
                        deliveryDate={deliveryDate}
                        itemsInPackage={product.items_in_package}
                        orderAsPackage={product.order_as_package}
                    />
                );
            },
        },
        {
            id: 'points',
            Header: <FormattedMessage id="table.header.points" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.points" />
            ),
            accessor: (d) => (d.campaign ? d.campaign.points : d.id),
            filterable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__campaign disable-sort-indicator',
            show: true,
            width: 'auto',
            sortable: false,
            Cell: (row) => {
                if (row.original.campaigns) {
                    return row.original.campaigns.map((campaign) => {
                        return (
                            <CampaignPointsBadgeContainer
                                key={campaign.id}
                                productId={row.original.id}
                                campaign={campaign}
                            />
                        );
                    });
                }
            },
        },
        {
            id: 'ordersHistory',
            Header: <FormattedMessage id="table.header.orders_history" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.orders_history" />
            ),
            filterable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__orders-history disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__orders-history disable-sort-indicator',
            show: true,
            width: 210,
            sortable: false,
            Cell: (row) => (
                <OrdersHistory
                    weeks={row.original.weeks}
                    inDelivery={row.original.in_delivery}
                    rowIndex={row.viewIndex}
                    providerError={row.original.has_orders_provider_error}
                />
            ),
        },
        {
            id: 'onOrderList',
            Header: <FormattedMessage id="table.header.on_order_list" />,
            preferencesLabel: (
                <FormattedMessage id="table.preferences.on_order_list" />
            ),
            accessor: 'onOrderList',
            filterable: false,
            sortable: false,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__on-order-list disable-sort-indicator',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__on-order-list disable-sort-indicator',
            show: true,
            width: 'auto',
            Cell: (row) =>
                row.original.in_assortment && (
                    <MyOrderListContainer
                        id={row.original.id}
                        onOrderList={row.original.in_assortment}
                    />
                ),
        },
    ];

    if (hideByDefault) {
        columns.forEach((column, index) => {
            if (hideByDefault.includes(column.id)) {
                columns[index].show = false;
            }
        });
    }

    if (doNotAdd) {
        columns = columns.filter((column) => !doNotAdd.includes(column.id));
    }

    return columns;
};

export default getColumns;

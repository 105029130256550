import PropTypes from 'prop-types';
import React from 'react';
import 'react-table/react-table.css';
import MasterTable from './MasterTable';
import getColumns from '../../Table/getColumns';
import { fetchProducts } from '../../../actions/masterTable';
import { connect } from 'react-redux';
import { toggleFilterable } from '../../../actions/table';
import * as qs from 'qs';
import history from '../../../history';
import { openDeliveryDatepicker } from '../../../actions';
import Product from '../../../services/api/Products';
import saveFile from '../../../services/fileSaver';
import {
    fetchUnreadServiceNotifications,
    fetchUserData,
} from '../../../actions/routines';
import * as R from 'ramda';

class MasterTableContainer extends React.Component {
    constructor(props) {
        super(props);

        const queryParams = qs.parse(history.location.search.slice(1));

        this.state = {
            columns: getColumns(
                ['points', 'onOrderList', 'weight'],
                undefined,
                ['price']
            ),
            whatColumnsToShowForProducts: localStorage.getItem(
                'whatColumnsToShowForProducts'
            )
                ? JSON.parse(
                      localStorage.getItem('whatColumnsToShowForProducts')
                  )
                : getColumns(['points', 'onOrderList', 'weight'], undefined, [
                      'price',
                  ]),
            pages: null,
            pageSize: 0,
            selectedProductType: this.detectProductType(queryParams),
            searchCampaign: false,
            showMyListButton: true,
            loadingDownloadAssortment: false,
            loadingDownloadAssortmentExcel: false,
        };
    }

    detectProductType = (queryParams) => {
        if (queryParams.kslist !== undefined && queryParams.kslist === 1) {
            return 2;
        }

        if (queryParams.mylist !== undefined) {
            return queryParams.mylist === 1 ? 1 : 0;
        }

        return 1; //default
    };

    componentDidMount() {
        this.props.fetchUnreadServiceNotifications();
        this.props.fetchUserData();

        const doNotAdd = [];
        if (!this.props.showPrices) {
            doNotAdd.push('price');
        }

        this.setState({
            columns: getColumns(
                ['points', 'onOrderList', 'weight'],
                undefined,
                doNotAdd
            ),
        });

        this.props.toggleFilterable();

        const { columns, whatColumnsToShowForProducts } = this.state;
        const updatedColumns = columns.map((column) => {
            const matchedColumn = whatColumnsToShowForProducts.find(
                (c) => c.id === column.id
            );
            if (matchedColumn) {
                return { ...column, show: matchedColumn.show };
            }
            return column;
        });
        this.setState({ columns: updatedColumns });
    }

    componentDidUpdate(prevProps, prevState) {
        const { deliveryDate, hasMyList, showPrices, campaigns } = this.props;

        const queryParams = qs.parse(history.location.search.slice(1));
        if (this.state.searchCampaign === false && queryParams['s_campaign']) {
            this.fetchData(queryParams);
            this.setState({
                searchCampaign: true,
            });
        }

        if (
            (prevProps.deliveryDate &&
                !R.equals(deliveryDate, prevProps.deliveryDate)) ||
            (prevProps.userDetails &&
                this.props.userDetails !== prevProps.userDetails)
        ) {
            queryParams.mylist = this.state.selectedProductType === 1 ? 1 : 0;
            queryParams.kslist = this.state.selectedProductType === 2 ? 1 : 0;

            history.push({
                ...this.props.location,
                search: qs.stringify(queryParams),
            });

            this.props.fetchProducts({
                deliveryDate: deliveryDate,
                params: queryParams,
            });
        }

        if (!hasMyList && this.state.showMyListButton) {
            this.setState({
                showMyListButton: false,
            });

            this.handleProductTypeSelect('0');
        }

        if (hasMyList && !this.state.showMyListButton) {
            this.setState({
                showMyListButton: true,
            });

            this.handleProductTypeSelect('1');
        }

        const doNotAdd = [];
        if (!showPrices) {
            doNotAdd.push('price');
        }

        const isExistPriceColumn = !!this.state.columns.filter(
            (column) => column.id === 'price'
        ).length;

        if (
            showPrices !== prevProps.showPrices ||
            (showPrices && !isExistPriceColumn)
        ) {
            const { whatColumnsToShowForProducts } = this.state;

            const updatedColumnsWithoutPrice = getColumns(
                ['points', 'onOrderList', 'weight'],
                undefined,
                doNotAdd
            );

            const updatedColumns = updatedColumnsWithoutPrice.map((column) => {
                const matchedColumn = whatColumnsToShowForProducts.find(
                    (c) => c.id === column.id
                );
                if (matchedColumn) {
                    return { ...column, show: matchedColumn.show };
                }
                return column;
            });
            this.setState({ columns: updatedColumns });
        }

        const isExistActiveCampaigns = !!campaigns.filter(
            (campaign) => campaign.is_active
        ).length;

        if (
            isExistActiveCampaigns &&
            isExistActiveCampaigns !== this.state.showCampaignPoints
        ) {
            const { whatColumnsToShowForProducts } = this.state;

            const updatedColumnsWithoutPrice = getColumns(
                ['onOrderList', 'weight'],
                undefined,
                doNotAdd
            );

            const updatedColumns = updatedColumnsWithoutPrice.map((column) => {
                const matchedColumn = whatColumnsToShowForProducts.find(
                    (c) => c.id === column.id
                );
                if (matchedColumn) {
                    return { ...column, show: matchedColumn.show };
                }
                return column;
            });
            this.setState({
                showCampaignPoints: true,
                columns: updatedColumns,
            });
        }
    }

    fetchData = (params) => {
        params.mylist = this.state.selectedProductType === 1 ? 1 : 0;
        params.kslist = this.state.selectedProductType === 2 ? 1 : 0;

        this.props.fetchProducts({
            deliveryDate: this.props.deliveryDate,
            params: params,
        });
    };

    handleProductTypeSelect = (type) => {
        type = parseInt(type);

        if (this.state.selectedProductType === type) {
            return;
        }

        this.setState({
            selectedProductType: type,
        });

        const queryParams = qs.parse(history.location.search.slice(1));
        queryParams.mylist = type === 1 ? 1 : 0;
        queryParams.kslist = type === 2 ? 1 : 0;

        history.push({
            ...this.props.location,
            search: qs.stringify(queryParams),
        });

        this.props.fetchProducts({
            deliveryDate: this.props.deliveryDate,
            params: queryParams,
        });
    };

    handleToggleColumn = (c) => {
        const columns = [];
        columns.push(...this.state.columns);
        columns.forEach((column, index) => {
            if (column.id === c.target.name) {
                columns[index].show = !column.show;
            }
        });
        const columnsObjectsWithBolean = columns.map((el) => ({
            id: el.id,
            show: el.show,
        }));
        localStorage.setItem(
            'whatColumnsToShowForProducts',
            JSON.stringify(columnsObjectsWithBolean)
        );
        this.setState({
            columns: columns,
            whatColumnsToShowForProducts: JSON.parse(
                localStorage.getItem('whatColumnsToShowForProducts')
            ),
        });
    };

    handleDownloadAssortment = () => {
        this.setState({
            loadingDownloadAssortment: true,
        });

        const params = this.initParameters();

        Product.getMyListPDF({ params }).then((data) => {
            this.setState({
                loadingDownloadAssortment: false,
            });
            saveFile(data, 'myassortment.pdf');
        });
    };

    handleDownloadAssortmentExcel = () => {
        this.setState({
            loadingDownloadAssortmentExcel: true,
        });

        const params = this.initParameters();

        Product.getMyListExcel({ params }).then((data) => {
            this.setState({
                loadingDownloadAssortmentExcel: false,
            });
            saveFile(data, 'myassortment.xlsx');
        });
    };

    initParameters() {
        const params = qs.parse(history.location.search.slice(1));
        params.mylist = this.state.selectedProductType === 1 ? 1 : 0;
        params.kslist = this.state.selectedProductType === 2 ? 1 : 0;

        return params;
    }

    getMyListExcel;

    render() {
        const {
            products,
            filterable,
            toggleFilterable,
            total,
            loading,
            openDeliveryDatepicker,
            serviceNotifications,
            showOnlyMyList,
            showKSListButton,
            hasMyList,
            isFairActive,
        } = this.props;
        const {
            columns,
            pages,
            selectedProductType,
            loadingDownloadAssortment,
            loadingDownloadAssortmentExcel,
        } = this.state;
        return (
            <MasterTable
                data={products}
                columns={columns}
                isFilterable={filterable}
                toggleFilterable={toggleFilterable}
                handleToggleColumn={this.handleToggleColumn}
                handleFetchData={this.fetchData}
                itemsCount={total}
                total={pages}
                loading={loading}
                handleProductTypeSelect={this.handleProductTypeSelect}
                selectedProductType={selectedProductType}
                openDeliveryDatepicker={openDeliveryDatepicker}
                handleDownloadAssortment={this.handleDownloadAssortment}
                loadingDownloadAssortment={loadingDownloadAssortment}
                handleDownloadAssortmentExcel={
                    this.handleDownloadAssortmentExcel
                }
                loadingDownloadAssortmentExcel={loadingDownloadAssortmentExcel}
                serviceNotifications={serviceNotifications}
                showOnlyMyList={showOnlyMyList}
                showKSListButton={showKSListButton}
                showMyListButton={hasMyList}
                isFairActive={isFairActive}
            />
        );
    }
}

MasterTableContainer.propTypes = {
    fetchProducts: PropTypes.func,
    fetchServiceNotifications: PropTypes.func,
    filterable: PropTypes.bool,
    loading: PropTypes.bool,
    location: PropTypes.any,
    serviceNotifications: PropTypes.array,
    openDeliveryDatepicker: PropTypes.func,
    products: PropTypes.array,
    toggleFilterable: PropTypes.func,
    total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    fetchUserData: PropTypes.func,
};

MasterTableContainer.defaultProps = {
    products: [],
    hasMyList: true,
};

const mapStateToProps = (state) => {
    const userDetails =
        state.userDetails.data && state.userDetails.data.user_details
            ? state.userDetails.data.user_details
            : null;

    const customerDetails =
        state.userDetails.data &&
        state.userDetails.data?.current_customer?.customer_details
            ? state.userDetails.data?.current_customer?.customer_details
            : null;

    return {
        products: state.masterTable.products,
        total: state.masterTable.total,
        hasMyList: state.masterTable.hasMyList,
        loading: state.loadingState.loading,
        loadingDownloadAssortment: state.loadingDownloadAssortment,
        loadingDownloadAssortmentExcel: state.loadingDownloadAssortmentExcel,
        filterable: state.table.filterable,
        serviceNotifications: state.unreadServiceNotifications.data,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
        showOnlyMyList: userDetails ? userDetails.show_only_my_list : false,
        showKSListButton: userDetails && userDetails.client_group === 'KS',
        showPrices: customerDetails ? customerDetails.show_price : false,
        showCampaignPoints: false,
        isFairActive:
            state.userDetails.data && state.userDetails.data.is_fair_active,
        campaigns: state.campaigns.data,
        userDetails: state.userDetails?.data?.current_customer?.id,
    };
};

const mapDispatchToProps = {
    fetchProducts,
    toggleFilterable,
    openDeliveryDatepicker,
    fetchUnreadServiceNotifications,
    fetchUserData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterTableContainer);

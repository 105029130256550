import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import * as R from 'ramda';
import placeholderImage from '../../../../assets/images/image-placeholder.svg';
import QuantityContainer from '../../../Quantity/QuantityContainer';
import PopoverBadge from '../../../Table/components/PopoverBadge';

const ProductBox = ({ product, openProductDetails }) => {
    const {
        id,
        name,
        images,
        items_in_package: itemsInPackage,
        order_as_package: orderAsPackage,
        promotions,
        amount,
    } = product;

    const mainImage = R.filter(R.propEq('image_type', 0))(images);

    return (
        <Card className="product-novelty__item-box">
            <div
                onClick={() => openProductDetails(id)}
                className="product-novelty__item-box__image-wrapper"
            >
                {mainImage.length > 0 ? (
                    <img
                        src={mainImage[0].url}
                        alt={name}
                        className="img-fluid"
                    />
                ) : (
                    <div className="product-novelty__item-box__image-placeholder">
                        <img
                            src={placeholderImage}
                            alt={name}
                            className="img-fluid"
                        />
                    </div>
                )}
            </div>
            <CardBody>
                <div className="product-novelty__item-box__body-wrapper">
                    <CardTitle
                        onClick={() => openProductDetails(id)}
                        className="product-novelty__item-box__title"
                    >
                        {name}
                    </CardTitle>

                    <div className="product-novelty__item-box__promotions">
                        {promotions && promotions.length
                            ? promotions.map((promotion) => {
                                  return (
                                      <PopoverBadge
                                          wrapperClassName="product-novelty__item-box__promotion"
                                          id={product.id}
                                          type="promotion"
                                          badgeText={`i`}
                                          backgroundColor={
                                              promotion.promotion_type === 1
                                                  ? '#FFDE00'
                                                  : '#D70000'
                                          }
                                          fontColor={
                                              promotion.promotion_type === 1
                                                  ? 'darkblue'
                                                  : 'white'
                                          }
                                          popoverHeader={promotion.promotion}
                                          popoverBody={`
                                            ${promotion.description}
                                            <br> <small>${moment(
                                                promotion.start_date
                                            ).format('DD/MM/YYYY')} - ${moment(
                                              promotion.end_date
                                          ).format(
                                              'DD/MM/YYYY'
                                          )}</small>                                           
                                        `}
                                          placement="top"
                                      />
                                  );
                              })
                            : ''}
                    </div>

                    <hr />
                    <div>
                        <span className="product-novelty__item-box__article-nr">
                            <FormattedMessage id={'product.product_no'} />{' '}
                            {product.article_nr}
                        </span>
                    </div>
                    <Row>
                        <Col className="product-novelty__item-box__price">
                            <span>{product.price.label}</span>
                        </Col>
                        <Col className="product-novelty__item-box__quantity">
                            <style>
                                {`
                                    .limit-max-order {
                                        position: absolute;
                                        width: 200px;
                                        left: -100px;
                                        top: 26px;
                                    }
                                `}
                            </style>
                            <QuantityContainer
                                product={product}
                                productId={id}
                                quantity={amount}
                                itemsInPackage={itemsInPackage}
                                orderAsPackage={orderAsPackage}
                                tabIndex={9292929292 + id} //Small hack to omit toplist in tab-ing
                            />
                        </Col>
                    </Row>
                </div>
            </CardBody>
        </Card>
    );
};

ProductBox.propTypes = {
    openProductDetails: PropTypes.func,
};

export default ProductBox;

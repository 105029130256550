import React from 'react';
import Login from "./Login";
import {connect} from "react-redux";
import {loginUser} from '../../../../actions/security';

class LoginContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            error: null,
            loading: false
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            error: nextProps.serverErrors
        })
    }

    handleLogin = async (e) => {
        e.preventDefault();

        this.props.loginUser({login: this.state.login, password: this.state.password});
    };

    handleOnChange = (event) => {
        this.setState({...this.state, [event.target.name]: event.target.value})
    };

    render() {
        return <Login
            login={this.state.login}
            password={this.state.password}
            error={this.state.error}
            loading={this.props.loading}
            onChange={this.handleOnChange}
            handleLogin={this.handleLogin}
        />
    }
}

const mapStateToProps = (state) => {
    return {
        serverErrors: state.apiErrors.security,
        loading: state.loadingState.loading
    }
};

export default connect(mapStateToProps, {loginUser: loginUser})(LoginContainer);

import PropTypes from 'prop-types'
import React from "react";
import PromoBox from "./PromoBox";

const BoxesPromotion = ({items}) => {
    return (
        <div className={`boxes-promotion ${items.length > 1 ? 'boxes-promotion--multiple' : ''}`}>
            {items.map(item => (
                <PromoBox
                    key={item.id}
                    product={item}
                    style={{width: `calc((100% / ${items.length}) - 25px)`}}
                />
            ))}
        </div>
    );
};

BoxesPromotion.propTypes = {
  items: PropTypes.array.isRequired
};

export default BoxesPromotion;

import React from 'react';
import PropTypes from 'prop-types';
import {Container} from "mdbreact";
import {FormattedMessage} from "react-intl";

const TitleHeader = ({title, icon, values, children}) => {
    return (
        <Container fluid className="full-width title-header">
            <div className="title-header__body">
                <h1 className="title-header__title"><img src={icon} alt="icon"/> <FormattedMessage id={title} values={values}/></h1>
                {children &&
                    children
                }
            </div>
        </Container>
    );
};

TitleHeader.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.string,
  title: PropTypes.string,
  values: PropTypes.object
};

export default TitleHeader;

import React from 'react';
import Spinner from '../../Utils/Spinner';

const ProductCounter = ({ loading, numberOfProducts, type, selectedType }) => {
    if (type !== selectedType) {
        return <span></span>;
    }

    if (loading) {
        return <Spinner className="spinner--white" width={15} height={15} />;
    }

    return numberOfProducts ? <span>({numberOfProducts})</span> : <span></span>;
};

export default ProductCounter;

import PropTypes from 'prop-types'
import React from "react";
import InputDefault from "../../../../Form/InputDefault";

const PasswordField = ({password, isEditActive, handlePasswordOnChange}) => {
    if (isEditActive) {
        return <InputDefault type="text" value={password} onChange={handlePasswordOnChange}/>
    }

    return <span>{password}</span>;
};

PasswordField.propTypes = {
  handlePasswordOnChange: PropTypes.func.isRequired,
  isEditActive: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired
};

export default PasswordField;

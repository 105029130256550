import PropTypes from 'prop-types'
import React from "react";
import {Col, Row} from "mdbreact";
import PasswordField from "./PasswordField";
import EditBtn from "./EditBtn";

const Department = ({id, name, password, isEditActive, isNewPasswordSent, toggleEdit, saveEdit, handlePasswordOnChange}) => {
    return (
        <Row className="my-account__departments-access__department">
            <Col size="4" className="my-account__departments-access__department__name">{name}</Col>
            <Col size="4" className="my-account__departments-access__department__password">
                <PasswordField password={password} isEditActive={isEditActive} handlePasswordOnChange={handlePasswordOnChange}/>
            </Col>
            <Col size="3" className="my-account__departments-access__department__btn">
                <EditBtn isEditActive={isEditActive} isNewPasswordSent={isNewPasswordSent} toggleEdit={toggleEdit} saveEdit={saveEdit}/>
            </Col>
        </Row>
    );
};

Department.propTypes = {
  handlePasswordOnChange: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isEditActive: PropTypes.bool.isRequired,
  isNewPasswordSent: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  saveEdit: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired
};

export default Department;

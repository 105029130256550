import {
    loginUserSaga,
    logoutUserSaga,
    requestNewPasswordSaga,
    sendNewPasswordResetTokenSaga,
    sendNewPasswordAccessTokenSaga,
} from './sagas/securitySagas';
import { takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchProductsSaga } from './sagas/masterTableSagas';
import { fetchProductDetailsSaga } from './sagas/productDetailsSaga';
import {
    fetchProductData,
    fetchUserData,
    fetchAllergensData,
    fetchActiveCartData,
    fetchDeliverySchema,
    fetchPromotions,
    deleteCart,
    addItemCart,
    addItemsCart,
    removeItemCart,
    createOrUpdateActiveCart,
    fetchDelivers,
    fetchProductTopList,
    fetchReadNotifications,
    fetchUnreadNotifications,
    fetchUnreadNormalNotifications,
    fetchUnreadServiceNotifications,
    setAsReadNotifications,
    fetchPromotionalModal,
    fetchCampaigns,
    completeCart,
    fetchRecipeCategories,
    fetchRecipeListByCategory,
    toggleRecipeFavourites,
    fetchRecipeDetails,
    fetchDownloadCenterFolders,
    fetchDownloadCenterFiles,
    searchDownloadCenterFiles,
    fetchFavouritesRecipeList,
    fetchProductCategories,
    fetchProductOrderHistory,
    searchRecipes,
    sendNewPasswordAccessToken,
    fetchFairCarts,
    fetchFairProducts,
    fetchFairSuppliers,
    removeFromAssortment,
    fetchFairPoints,
    completeFairCart,
    sendRetourForm,
    fetchFairDynamicWeeksData,
    addFairDynamicWeek,
    fetchNoveltiesProducts,
} from './actions/routines';
import { fetchUserDetailsSaga } from './sagas/userDetailsSaga';
import { fetchAllergensSaga } from './sagas/allergensSagas';
import {
    fetchActiveCartSaga,
    deleteCartSaga,
    addProductToCartSaga,
    addProductsToCartSaga,
    removeProductFromCartSaga,
    createOrUpdateActiveCartSaga,
    completeCartSaga,
} from './sagas/cartSaga';
import {
    fetchDeliversSaga,
    fetchDeliverySchemaSaga,
} from './sagas/deliverySaga';
import { fetchPromotionsSaga } from './sagas/promotionsSagas';
import { fetchProductTopListSaga } from './sagas/productTopListSagas';
import {
    fetchReadNotificationsSaga,
    fetchUnreadNotificationsSaga,
    fetchUnreadNormalNotificationsSaga,
    fetchUnreadServiceNotificationsSaga,
    setAsReadNotificationsSaga,
} from './sagas/notificationsSagas';
import { fetchPromotionalModalSaga } from './sagas/promotionalModalSagas';
import { campaignSaga } from './sagas/campaignSagas';
import { fetchRecipeCategoriesSaga } from './sagas/recipeCategoriesSagas';
import {
    toggleRecipeFavouritesSaga,
    fetchRecipesByCategorySaga,
    fetchRecipeDetailsSaga,
    fetchFavouriteRecipesSaga,
    searchRecipesSaga,
} from './sagas/recipeSagas';
import {
    fetchDownloadCenterFilesSaga,
    fetchDownloadCenterFoldersSaga,
    searchDownloadCenterFilesSaga,
} from './sagas/DownloadCenterSagas';
import { fetchProductCategoriesSaga } from './sagas/productCategoriesSagas';
import { fetchProductOrderHistorySaga } from './sagas/productOrderHistorySaga';
import {
    fetchCartsSaga as fetchFairCartsSaga,
    fetchProductsSaga as fetchFairProductsSaga,
    fetchSuppliersSaga,
    fetchPointsSaga,
    completeFairCartSaga,
    fetchDynamicWeeksDataSaga,
    addFairDynamicWeekSaga,
} from './sagas/fairSaga';
import { removeFromAssortmentSaga } from './sagas/productAssortmentSagas';
import { sendRetourFormSaga } from './sagas/retourSaga';
import { fetchNoveltiesProductsSaga } from './sagas/noveltySagas';

export default function* rootSaga() {
    yield takeLatest('LOGIN_USER', loginUserSaga);
    yield takeLatest('LOGOUT_USER', logoutUserSaga);
    yield takeLatest('NEW_PASSWORD_REQUEST', requestNewPasswordSaga);
    yield takeLatest(
        'NEW_PASSWORD_SEND_RESET_TOKEN',
        sendNewPasswordResetTokenSaga
    );
    yield takeLatest(
        sendNewPasswordAccessToken.TRIGGER,
        sendNewPasswordAccessTokenSaga
    );
    yield takeLatest('FETCH_PRODUCTS', fetchProductsSaga);
    yield takeEvery(fetchProductData.TRIGGER, fetchProductDetailsSaga);
    yield takeEvery(fetchUserData.TRIGGER, fetchUserDetailsSaga);
    yield takeEvery(fetchAllergensData.TRIGGER, fetchAllergensSaga);
    yield takeEvery(fetchProductCategories.TRIGGER, fetchProductCategoriesSaga);

    yield takeEvery(fetchActiveCartData.TRIGGER, fetchActiveCartSaga);
    yield takeEvery(deleteCart.TRIGGER, deleteCartSaga);
    yield takeEvery(addItemCart.TRIGGER, addProductToCartSaga);
    yield takeEvery(addItemsCart.TRIGGER, addProductsToCartSaga);
    yield takeEvery(removeItemCart.TRIGGER, removeProductFromCartSaga);
    yield takeEvery(
        createOrUpdateActiveCart.TRIGGER,
        createOrUpdateActiveCartSaga
    );
    yield takeEvery(completeCart.TRIGGER, completeCartSaga);

    yield takeEvery(fetchDeliverySchema.TRIGGER, fetchDeliverySchemaSaga);
    yield takeEvery(fetchDelivers.TRIGGER, fetchDeliversSaga);

    yield takeEvery(fetchPromotions.TRIGGER, fetchPromotionsSaga);
    yield takeEvery(fetchProductTopList.TRIGGER, fetchProductTopListSaga);

    yield takeEvery(fetchReadNotifications.TRIGGER, fetchReadNotificationsSaga);
    yield takeEvery(
        fetchUnreadNotifications.TRIGGER,
        fetchUnreadNotificationsSaga
    );
    yield takeEvery(
        fetchUnreadNormalNotifications.TRIGGER,
        fetchUnreadNormalNotificationsSaga
    );
    yield takeEvery(
        fetchUnreadServiceNotifications.TRIGGER,
        fetchUnreadServiceNotificationsSaga
    );
    yield takeEvery(setAsReadNotifications.TRIGGER, setAsReadNotificationsSaga);

    yield takeEvery(fetchPromotionalModal.TRIGGER, fetchPromotionalModalSaga);

    yield takeEvery(fetchCampaigns.TRIGGER, campaignSaga);

    yield takeEvery(fetchRecipeCategories.TRIGGER, fetchRecipeCategoriesSaga);
    yield takeEvery(
        fetchRecipeListByCategory.TRIGGER,
        fetchRecipesByCategorySaga
    );
    yield takeEvery(toggleRecipeFavourites.TRIGGER, toggleRecipeFavouritesSaga);
    yield takeEvery(fetchRecipeDetails.TRIGGER, fetchRecipeDetailsSaga);
    yield takeEvery(
        fetchFavouritesRecipeList.TRIGGER,
        fetchFavouriteRecipesSaga
    );
    yield takeEvery(searchRecipes.TRIGGER, searchRecipesSaga);

    yield takeEvery(
        fetchDownloadCenterFolders.TRIGGER,
        fetchDownloadCenterFoldersSaga
    );
    yield takeEvery(
        fetchDownloadCenterFiles.TRIGGER,
        fetchDownloadCenterFilesSaga
    );
    yield takeEvery(
        searchDownloadCenterFiles.TRIGGER,
        searchDownloadCenterFilesSaga
    );

    yield takeEvery(
        fetchProductOrderHistory.TRIGGER,
        fetchProductOrderHistorySaga
    );

    yield takeEvery(fetchFairCarts.TRIGGER, fetchFairCartsSaga);
    yield takeEvery(fetchFairProducts.TRIGGER, fetchFairProductsSaga);
    yield takeEvery(fetchFairSuppliers.TRIGGER, fetchSuppliersSaga);
    yield takeEvery(fetchFairPoints.TRIGGER, fetchPointsSaga);
    yield takeEvery(
        fetchFairDynamicWeeksData.TRIGGER,
        fetchDynamicWeeksDataSaga
    );
    yield takeEvery(addFairDynamicWeek.TRIGGER, addFairDynamicWeekSaga);
    yield takeEvery(completeFairCart.TRIGGER, completeFairCartSaga);

    yield takeEvery(removeFromAssortment.TRIGGER, removeFromAssortmentSaga);

    yield takeEvery(sendRetourForm.TRIGGER, sendRetourFormSaga);

    yield takeLatest('USER_LOGGED_IN', fetchUserDetailsSaga);

    yield takeEvery(fetchNoveltiesProducts.TRIGGER, fetchNoveltiesProductsSaga);
}

import React from 'react';
import logo from '../../../assets/images/logo.svg';
import {Col, Row} from "mdbreact";

const Header = () => {
    return (
        <Row>
            <Col>
                <img className="dashboard__logo" src={logo} alt="logo"/>
            </Col>
        </Row>
    );
};

export default Header;

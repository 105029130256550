import TitleHeader from '../components/TitleHeader';
import recipesGrey from '../../../assets/images/recipes-grey.svg';
import { Spinner } from 'mdbreact';
import React from 'react';
import PropTypes from 'prop-types';
import MasonryInfiniteScroller from 'react-masonry-infinite';
import ProductBox from './components/ProducBox';
import { newReleaseGrey } from '../../../assets/images/icons';

const Novelty = ({
    products,
    hasMore,
    isLoading,
    loadMore,
    title,
    titleValues,
    openProductDetails,
}) => {
    return (
        <div className={`recipes recipes__list`}>
            <TitleHeader
                icon={newReleaseGrey}
                title={title}
                values={titleValues}
            ></TitleHeader>

            <MasonryInfiniteScroller
                hasMore={!isLoading && hasMore}
                loadMore={loadMore}
                sizes={[
                    { columns: 3, gutter: 20 },
                    { mq: '1200px', columns: 4, gutter: 20 },
                ]}
                className="mb-5 mx-auto"
            >
                {products.map((product) => {
                    return (
                        <ProductBox
                            key={product.id}
                            product={product}
                            openProductDetails={openProductDetails}
                        />
                    );
                })}
            </MasonryInfiniteScroller>

            {isLoading && (
                <div className="flex-center">
                    {' '}
                    <Spinner className="brand-color" />
                </div>
            )}
        </div>
    );
};

Novelty.propTypes = {
    hasMore: PropTypes.bool,
    isLoading: PropTypes.any,
    loadMore: PropTypes.func,
    products: PropTypes.array,
    title: PropTypes.string,
    titleValues: PropTypes.object,
};

Novelty.defaultProps = {
    title: 'menu.novelty',
    titleValues: {},
};

export default Novelty;

import React from 'react';
import getColumnsForCart from '../../../../Table/getColumnsForCart';
import Cart from './Cart';
import DeleteRow from '../../../../Table/components/DeleteRow/DeleteRow';
import swal from 'sweetalert2';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import { connect } from 'react-redux';
import bindPromiseCreators from 'redux-saga-routines/es/bindPromiseCreators';
import {
    completeCartPromiseCreator,
    deleteCartPromiseCreator,
} from '../../../../../actions/routines';
import { bindActionCreators } from 'redux';
import { openDeliveryDatepicker } from '../../../../../actions';

class CartContainer extends React.Component {
    constructor(props) {
        super(props);
        const { showPrice } = this.props;
        this.state = {
            columns: getColumnsForCart(
                ['available', 'ordersHistory'],
                undefined,
                ['onOrderList', 'price'],
                showPrice
            ),
            animateRemove: false,
            showDialog: false,
            status: 'success',
            exportedStatus: 'S',
            basketItems: [],
        };
    }

    componentDidMount() {
        this.addDeleteColumn();
    }

    componentDidUpdate(prevProps, prevState) {
        const { showPrice } = this.props;
        if (prevProps.showPrice !== showPrice) {
            this.setState({
                columns: [
                    ...getColumnsForCart(
                        ['available', 'ordersHistory'],
                        undefined,
                        ['onOrderList', 'price'],
                        showPrice
                    ),
                    this.getDeleteColumn(),
                ],
            });
        }
    }

    getDeleteColumn = () => {
        const { handleRemoveProduct, id } = this.props;

        return {
            id: 'delete',
            Header: '',
            accessor: 'delete',
            filterable: false,
            width: 45,
            headerClassName:
                'table__rt__col table__rt__col--fix-width table__rt__delete',
            className:
                'table__rt__col table__rt__col--fix-width table__rt__delete',
            show: true,
            Cell: (row) => {
                return (
                    <DeleteRow
                        onClick={() => handleRemoveProduct(id, row.original.id)}
                    />
                );
            },
        };
    };

    addDeleteColumn = () => {
        this.setState({
            columns: [...this.state.columns, this.getDeleteColumn()],
        });
    };

    handleRemoveAnimation = () => {
        const { handleCartRemove, deleteCartPromiseCreator, id, intl } =
            this.props;

        const swalWithBootstrapButtons = swal.mixin({
            confirmButtonClass: 'btn btn-danger btn-sm btn-flat',
            cancelButtonClass: 'btn btn-grey btn-sm btn-flat',
            buttonsStyling: false,
        });

        swalWithBootstrapButtons({
            title: intl.formatMessage({
                id: 'cart.confirmation_modal.are_you_sure',
            }),
            showCancelButton: true,
            confirmButtonText: intl.formatMessage({
                id: 'cart.confirmation_modal.yes',
            }),
            cancelButtonText: intl.formatMessage({
                id: 'cart.confirmation_modal.no',
            }),
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                const promise = deleteCartPromiseCreator();
                promise.then(() => {
                    handleCartRemove(id);
                    this.setState({
                        showDialog: false,
                    });
                });
            }
        });
    };

    getStatusByExportedStatus = (exportedStatus) => {
        switch (exportedStatus) {
            case 'E':
                return 'error';
            case 'P':
            case 'D':
            case 'F':
                return 'warning';
            default:
                return 'success';
        }
    };
    makeOrder = () => {
        if (!this.props.date) {
            return;
        }

        this.props
            .completeCartPromiseCreator()
            .then((data) => {
                this.setState({
                    showDialog: true,
                    status: this.getStatusByExportedStatus(
                        data?.exported_status
                    ),
                    exportedStatus: data?.exported_status,
                    basketItems: data?.basket_items,
                });
            })
            .catch((err) => {
                this.setState({
                    showDialog: true,
                    status: 'error',
                });
            });
    };

    handleCloseNotification = () => {
        const { handleCartRemove, id } = this.props;

        this.setState(
            {
                showDialog: false,
            },
            () => {
                handleCartRemove(id);
            }
        );
    };

    render() {
        const {
            id,
            data,
            date,
            deleteLoading,
            handleCartRemove,
            fetchingActiveLoading,
            completeOrderLoading,
            updateAmountOfItemLoading,
            openDeliveryDatepicker,
            updateDateCartLoading,
            deliveryDates,
            userDetails,
            showHeader,
            showFooter,
            showOrderNumberInput,
            hideHead,
            hideBody,
            tableClassName,
            tableOnClick,
            subtotal,
            fullCart,
            dataMainDelivery,
            title,
            showPrice,
            campaigns,
        } = this.props;

        return (
            <Cart
                id={id}
                title={title}
                dataMainDelivery={dataMainDelivery}
                data={data}
                date={moment(date).format('DD/MM/YYYY')}
                columns={this.state.columns}
                deleteLoading={deleteLoading}
                showDialog={this.state.showDialog}
                status={this.state.status}
                exportedStatus={this.state.exportedStatus}
                basketItems={this.state.basketItems}
                animateRemove={this.state.animateRemove}
                handleCartRemove={handleCartRemove}
                handleRemoveAnimation={this.handleRemoveAnimation}
                makeOrder={this.makeOrder}
                handleCloseNotification={this.handleCloseNotification}
                fetchingActiveLoading={fetchingActiveLoading}
                completeOrderLoading={completeOrderLoading}
                updateAmountOfItemLoading={updateAmountOfItemLoading}
                updateDateCartLoading={updateDateCartLoading}
                openDeliveryDatepicker={openDeliveryDatepicker}
                deliveryDates={deliveryDates}
                userDetails={userDetails}
                showHeader={showHeader}
                showFooter={showFooter}
                showOrderNumberInput={showOrderNumberInput}
                hideHead={hideHead}
                hideBody={hideBody}
                tableClassName={tableClassName}
                tableOnClick={tableOnClick}
                subtotal={subtotal}
                fullCart={fullCart}
                showPrice={showPrice}
                campaigns={campaigns}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        deleteLoading: state.deleteCart.loading,
        fetchingActiveLoading: state.activeCart.loading,
        completeOrderLoading: state.completeCart.loading,
        updateAmountOfItemLoading: state.addItemsCart.loading,
        updateDateCartLoading: state.createOrUpdateActiveCart.loading,
        deliveryDates: state.deliverySchema.data,
        userDetails: state.userDetails.data,
        campaigns: state.campaigns.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindPromiseCreators(
            {
                deleteCartPromiseCreator,
                completeCartPromiseCreator,
            },
            dispatch
        ),
        ...bindActionCreators(
            {
                openDeliveryDatepicker,
            },
            dispatch
        ),
    };
};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(CartContainer)
);

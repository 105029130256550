import React from "react";
import {FormattedMessage} from "react-intl";
import history from '../../../history';

const RedirectToFairButton = () => (
    <div className="redirect-to-fair-button" onClick={() => history.push('/fair')}>
        <div className="redirect-to-fair-button__label">
            <FormattedMessage id="table.redirect_to_fair.label"/>
        </div>
    </div>
);


export default RedirectToFairButton;
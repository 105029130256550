import PropTypes from 'prop-types'
import React from 'react';
import Search from "../../../Search/Search";
import {injectIntl} from "react-intl";
import {fetchPromotions} from "../../../../actions/routines";
import {connect} from "react-redux";

class SearchContainer extends React.Component {
    search = (params) => {
        this.props.fetchPromotions(params);
    };

    render() {
        const {intl} = this.props;

        return (
            <Search
                placeholder={intl.formatMessage({id: "promotions.search.placeholder"})}
                label="promotions.search.btn"
                onSubmit={this.search}
            />
        );
    }
}

SearchContainer.propTypes = {
  intl: PropTypes.object.isRequired
};

export default injectIntl(connect(null, {fetchPromotions})(SearchContainer));

import PropTypes from 'prop-types'
import React from "react";
import {Animation, Button} from "mdbreact";
import {FormattedMessage} from "react-intl";
import editBlack from "../../../../../assets/images/edit-black.svg";
import {closeSmallGrey} from "../../../../../assets/images/icons";
import checkWhite from "../../../../../assets/images/check-white.svg";

const EditBtn = ({isEditActive, saveEdit, toggleEdit, isNewPasswordSent}) => {
    if (isEditActive) {
        return (
            <React.Fragment>
                {isNewPasswordSent &&
                <Button color="success">
                    <Animation type="bounceIn">
                        <img src={checkWhite} alt="saved" width="22px"/>
                    </Animation>
                </Button>
                }
                {!isNewPasswordSent &&
                <React.Fragment>
                    <Button onClick={saveEdit} className="btn-brand btn-flat">
                        <FormattedMessage id="my_account.access.department.save"/>
                    </Button>
                    <img onClick={toggleEdit} src={closeSmallGrey} alt="edit"/>
                </React.Fragment>
                }
            </React.Fragment>
        )
    }

    return <img className="my-account__departments-access__department__btn__edit" src={editBlack} onClick={toggleEdit} alt="edit"/>
};

EditBtn.propTypes = {
  isEditActive: PropTypes.bool.isRequired,
  isNewPasswordSent: PropTypes.bool.isRequired,
  saveEdit: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired
};

export default EditBtn;

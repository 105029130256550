import React from 'react';
import {connect} from "react-redux";
import {fetchCampaigns} from "../../actions/routines";
import CampaignSticker from './CampaignSticker';

class CampaignStickers extends React.Component {
    componentDidMount() {
        this.props.fetchCampaigns();
    }

    render() {
        return (
            <div className="campaign-stickers">
                {
                    this.props.campaigns.map(campaign => {
                        return <CampaignSticker key={campaign.id} campaign={campaign}/>
                    })
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        campaigns: state.campaigns.data
    }
};

export default connect(mapStateToProps, {fetchCampaigns})(CampaignStickers);

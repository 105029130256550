import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'mdbreact';
import history from '../../../../history';

const ContinueOrderButton = () => {
    const clickHandle = () => {
        history.push('/products');
    };
    return (
        <Button
            className="order-button btn-brand--with-shadow continue-order-button"
            onClick={clickHandle}
            color="darkorange"
            style={{ backgroundColor: 'darkorange' }}
        >
            <p>
                <FormattedMessage id="cart.continue_btn" />
            </p>
        </Button>
    );
};

export default ContinueOrderButton;

import PropTypes from 'prop-types'
import React from 'react';
import Search from "../../../Search/Search";
import {injectIntl} from "react-intl";
import {connect} from "react-redux";
import {fetchDelivers} from "../../../../actions/routines";

class SearchContainer extends React.Component {

    search = (params) => {
        this.props.fetchDelivers(params);
    };

    render() {
        const {intl, loading} = this.props;

        return (
            <Search
                placeholder={intl.formatMessage({id: "delivery.search.placeholder"})}
                label="delivery.search.btn"
                onSubmit={this.search}
                loading={loading}
            />
        );
    }
}

SearchContainer.propTypes = {
  intl: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        loading: state.delivers.loading
    }
};

export default injectIntl(connect(mapStateToProps, {fetchDelivers})(SearchContainer));
import PropTypes from 'prop-types';
import React from 'react';
import {
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from 'mdbreact';
import {
    allProducts,
    myProducts,
    searchGrey,
} from '../../../assets/images/icons';
import ProductTypeFilter from './Button/ProductTypeFilter';
import DownloadAssortmentButton from './DownloadAssortmentButton';
import RedirectToFairButton from './RedirectToFairButton';
import CampaignStickers from '../../CampaignPoints/CampaignStickers';
import { FormattedMessage } from 'react-intl';
import KeurslagerLabel from './Button/KeurslagerLabel';
import ProductCounter from './ProductCounter';
import KSicon from '../../../assets/images/keurslager-icon.png';
import Preferences from './Preferences';

const TableHeader = ({
    columns,
    selectedType,
    toggleFilterable,
    handleDownloadAssortment,
    handleDownloadAssortmentExcel,
    myListLabel,
    allItemsLabel,
    itemsCount,
    loading,
    handleToggleColumn,
    handleProductTypeSelect,
    showOnlyMyList,
    showMyListButton,
    showKSListButton,
    isFairActive,
    loadingDownloadAssortment,
    loadingDownloadAssortmentExcel,
}) => {
    function getIcon(selectedType) {
        switch (selectedType) {
            case 1:
                return myProducts;
            case 2:
                return KSicon;
            default:
                return allProducts;
        }
    }

    function getLabel(selectedType) {
        switch (selectedType) {
            case 1:
                return myListLabel;
            case 2:
                return 'table.keurslager_list.label';
            default:
                return allItemsLabel;
        }
    }

    return (
        <div className="table__header">
            <Row>
                <Col className="d-flex align-items-center">
                    {handleProductTypeSelect && (
                        <React.Fragment>
                            <Dropdown>
                                <DropdownToggle nav caret>
                                    <img
                                        className="homepage__navbar__icon"
                                        src={getIcon(selectedType)}
                                        alt="products"
                                    />
                                    <div className="product-type-filter__label">
                                        <FormattedMessage
                                            id={getLabel(selectedType)}
                                        />{' '}
                                        <ProductCounter
                                            loading={loading}
                                            numberOfProducts={itemsCount}
                                            type={selectedType}
                                            selectedType={selectedType}
                                        />
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className="product-switch-item">
                                    {showMyListButton && (
                                        <DropdownItem className="products-dropdown-menu">
                                            <div>
                                                <img
                                                    src={myProducts}
                                                    alt="my products"
                                                    className="product__navbar__icon"
                                                />
                                                <ProductTypeFilter
                                                    type={1}
                                                    label={
                                                        <FormattedMessage
                                                            id={myListLabel}
                                                        />
                                                    }
                                                    numberOfProducts={
                                                        itemsCount
                                                    }
                                                    handleProductTypeSelect={
                                                        handleProductTypeSelect
                                                    }
                                                    selectedType={selectedType}
                                                    loading={loading}
                                                />
                                            </div>
                                        </DropdownItem>
                                    )}
                                    {!showOnlyMyList && (
                                        <DropdownItem className="products-dropdown-menu">
                                            <div>
                                                <img
                                                    src={allProducts}
                                                    alt="all products"
                                                    className="product__navbar__icon"
                                                />
                                                <ProductTypeFilter
                                                    type={0}
                                                    label={
                                                        <FormattedMessage
                                                            id={allItemsLabel}
                                                        />
                                                    }
                                                    numberOfProducts={
                                                        itemsCount
                                                    }
                                                    handleProductTypeSelect={
                                                        handleProductTypeSelect
                                                    }
                                                    selectedType={selectedType}
                                                    loading={loading}
                                                />
                                            </div>
                                        </DropdownItem>
                                    )}

                                    {showKSListButton && (
                                        <DropdownItem className="products-dropdown-menu">
                                            <div className="table__header__actions-col">
                                                <ProductTypeFilter
                                                    type={2}
                                                    label={<KeurslagerLabel />}
                                                    numberOfProducts={
                                                        itemsCount
                                                    }
                                                    handleProductTypeSelect={
                                                        handleProductTypeSelect
                                                    }
                                                    selectedType={selectedType}
                                                    loading={loading}
                                                />
                                            </div>
                                        </DropdownItem>
                                    )}
                                </DropdownMenu>
                            </Dropdown>
                        </React.Fragment>
                    )}
                    {isFairActive && (
                        <div className="table__header__actions-col2">
                            <RedirectToFairButton />
                        </div>
                    )}
                    <div className="table__header__actions-col">
                        <div className="table__header__actions-col">
                            <CampaignStickers />
                        </div>
                        <div className="table__header__actions-col">
                            <Preferences
                                title="product.search_preferences"
                                hideIcon={true}
                                isHidden={false}
                                columns={columns}
                                handleToggleColumn={handleToggleColumn}
                            />
                        </div>
                    </div>

                    {handleProductTypeSelect && (
                        <React.Fragment>
                            {showMyListButton && (
                                <div className="table__header__actions-col--right">
                                    <div
                                        style={{ float: 'left' }}
                                        className="ml-4 mt-1 mr-1"
                                    >
                                        <FormattedMessage id="table.download_assortment.label" />
                                        {': '}
                                    </div>
                                    <div style={{ float: 'left' }}>
                                        <DownloadAssortmentButton
                                            handleDownloadAssortment={
                                                handleDownloadAssortment
                                            }
                                            downloadFormat="pdf"
                                            loading={loadingDownloadAssortment}
                                        />
                                    </div>
                                    <div style={{ float: 'left' }}>
                                        <DownloadAssortmentButton
                                            handleDownloadAssortment={
                                                handleDownloadAssortmentExcel
                                            }
                                            downloadFormat="excel"
                                            loading={
                                                loadingDownloadAssortmentExcel
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )}

                    <div className="filter-btn">
                        <img
                            onClick={toggleFilterable}
                            src={searchGrey}
                            alt="search"
                            className="table__header__search"
                        />
                    </div>
                </Col>
            </Row>
        </div>
    );
};

TableHeader.propTypes = {
    allItemsLabel: PropTypes.string,
    columns: PropTypes.array,
    myListLabel: PropTypes.string,
    handleDownloadAssortment: PropTypes.func,
    handleDownloadAssortmentExcel: PropTypes.func,
    handleToggleColumn: PropTypes.func,
    toggleFilterable: PropTypes.func,
};

export default TableHeader;

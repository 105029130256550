import React from 'react';
import PropTypes from 'prop-types';
import Table from '../../Table/Table';
import NotificationContainer from '../../Notification/NotificationContainer';
import TableHeader from '../../Table/components/TableHeader';
import MasterTableHeader from './components/MasterTableHeader/MasterTableHeader';
import { FormattedMessage } from 'react-intl';

const MasterTable = ({
    data,
    columns,
    pages,
    loading,
    itemsCount,
    selectedProductType,
    isFilterable,
    toggleFilterable,
    handleToggleColumn,
    handleFetchData,
    handleProductTypeSelect,
    openDeliveryDatepicker,
    handleDownloadAssortment,
    loadingDownloadAssortment,
    handleDownloadAssortmentExcel,
    loadingDownloadAssortmentExcel,
    serviceNotifications,
    showOnlyMyList,
    showMyListButton,
    isFairActive,
    showKSListButton,
}) => {
    return (
        <div>
            <MasterTableHeader
                openDeliveryDatepicker={openDeliveryDatepicker}
            />
            {serviceNotifications &&
                serviceNotifications.map((notification) => {
                    return (
                        <NotificationContainer
                            key={notification.id}
                            notificationId={notification.id}
                            type="inline"
                            title={
                                <FormattedMessage
                                    id={'notifications.type.service'}
                                />
                            }
                            text={notification.notification}
                            show={true}
                            url={notification.link}
                        />
                    );
                })}

            <TableHeader
                columns={columns}
                toggleFilterable={toggleFilterable}
                handleToggleColumn={handleToggleColumn}
                myListLabel="table.my_order_list_download"
                allItemsLabel="table.product_counter.label"
                keurslagerItemsLabel="table.keurslager_list.label"
                handleDownloadAssortment={handleDownloadAssortment}
                loadingDownloadAssortment={loadingDownloadAssortment}
                handleDownloadAssortmentExcel={handleDownloadAssortmentExcel}
                loadingDownloadAssortmentExcel={loadingDownloadAssortmentExcel}
                handleProductTypeSelect={handleProductTypeSelect}
                selectedType={selectedProductType}
                itemsCount={itemsCount}
                loading={loading}
                showOnlyMyList={showOnlyMyList}
                showMyListButton={showMyListButton}
                showKSListButton={showKSListButton}
                isFairActive={isFairActive}
            />
            <Table
                data={data}
                columns={columns}
                handleToggleColumn={handleToggleColumn}
                filterable={isFilterable}
                manual
                onFetchData={(state) => handleFetchData(state)}
                pages={pages}
                loading={loading}
                itemsCount={itemsCount}
                selectedProductType={selectedProductType}
                withNotification={serviceNotifications.length !== 0}
            />
        </div>
    );
};

MasterTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    isFilterable: PropTypes.bool.isRequired,
    toggleFilterable: PropTypes.func.isRequired,
    handleToggleColumn: PropTypes.func.isRequired,
};

export default MasterTable;

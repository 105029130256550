import PropTypes from 'prop-types'
import React from 'react';
import {Carousel, CarouselInner, CarouselItem, View} from 'mdbreact';
import ImagePromotion from "./ImagePromotion";
import BoxesPromotion from "./BoxesPromotion";

const CarouselPromotion = ({promotions}) => {
    return (
        <Carousel
            activeItem={1}
            length={promotions.length}
            showControls={true}
            showIndicators={true}
            interval={10000}
        >
            <CarouselInner>
                { promotions && promotions.map((promotion, index) => {
                    return (
                        <CarouselItem
                            key={promotion.id}
                            itemId={index + 1}
                            className={promotion.type === 0 ? 'promotional-modal__item--simple' : 'promotional-modal__item--boxes'}
                        >
                            <View>
                                {promotion.type === 0 ? <ImagePromotion data={promotion}/> : <BoxesPromotion items={promotion.products}/>}
                            </View>
                        </CarouselItem>
                    )
                })}
            </CarouselInner>
        </Carousel>
    );
};


CarouselPromotion.propTypes = {
  promotions: PropTypes.array.isRequired
};

export default CarouselPromotion;

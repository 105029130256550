import React from 'react';
import PropTypes from 'prop-types';
import ProductCounter from "../ProductCounter";

const ProductTypeFilter = ({type, selectedType, numberOfProducts, label, loading, handleProductTypeSelect}) => (
    <div onClick={() => handleProductTypeSelect(type)}
         className={`product-type-filter ${type === selectedType ? 'product-type-filter--active' : ''} ${loading ? 'product-type-filter--loading' : ''}`}
    >
        <div className="product-type-filter__label">
            {label} {' '}
            <ProductCounter
                loading={loading}
                numberOfProducts={numberOfProducts}
                type={type}
                selectedType={selectedType}
            />
        </div>
    </div>
);

ProductTypeFilter.propTypes = {
  handleProductTypeSelect: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
  numberOfProducts: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selectedType: PropTypes.number,
  type: PropTypes.number
};

export default ProductTypeFilter;

import React from 'react';
import PropTypes from 'prop-types';
import minus from '../../assets/images/minus.svg';
import plus from '../../assets/images/plus.svg';

class Quantity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false,
            wrapperRef: undefined,
        };
    }

    setWrapperRef = (node) => {
        this.setState({
            wrapperRef: node,
        });
    };

    setActive = () => {
        this.setState({
            isActive: true,
        });
    };

    unsetActive = () => {
        this.setState({
            isActive: false,
        });
    };

    render() {
        const {
            value,
            handleChange,
            handleIncrement,
            handleDecrement,
            tabIndex,
            addItemToCart,
            setRef,
            goToNextInputKeyPress,
        } = this.props;

        return (
            <div ref={this.setWrapperRef} className="quantity">
                <img
                    onClick={handleDecrement}
                    className="quantity__icon"
                    src={minus}
                    alt="minus"
                />
                <input
                    onClick={this.setActive}
                    onBlur={(e) => {
                        this.unsetActive();
                        this.props.requestForInputBlur && addItemToCart(e);
                    }}
                    onFocus={this.setActive}
                    onChange={handleChange}
                    className={`quantity__input ${
                        this.state.isActive ? 'quantity__input__active' : ''
                    }`}
                    type="text"
                    value={value === 0 && this.state.isActive ? '' : value}
                    tabIndex={tabIndex}
                    ref={setRef}
                    onKeyDown={goToNextInputKeyPress}
                />
                <img
                    onClick={handleIncrement}
                    className="quantity__icon"
                    src={plus}
                    alt="plus"
                />
            </div>
        );
    }
}

Quantity.propTypes = {
    value: PropTypes.number.isRequired,
    handleIncrement: PropTypes.func.isRequired,
    handleDecrement: PropTypes.func.isRequired,
    // addItemToCart: PropTypes.func.isRequired,
    tabIndex: PropTypes.number,
};

export default Quantity;

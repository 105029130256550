import PropTypes from 'prop-types';
import React from 'react';
import { preferencesGrey } from '../../../assets/images/icons';
import CardPopupList from '../../Utils/CardPopup/CardPopupList';
import { Input } from 'mdbreact';
import swal from 'sweetalert2';
import { FormattedMessage, injectIntl } from 'react-intl';

class Preferences extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpened: false,
            selectedColumnsCount: 0,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        this.calculateCountOfSelected();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.columns !== this.props.columns) {
            this.calculateCountOfSelected();
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.setState({
            wrapperRef: node,
        });
    };

    toggleOpen = () => {
        this.setState((prevState) => {
            return {
                isOpened: !prevState.isOpened,
            };
        });
    };

    handleClickOutside = (event) => {
        if (
            this.state.wrapperRef &&
            !this.state.wrapperRef.contains(event.target)
        ) {
            this.setState({
                isOpened: false,
            });
        }
    };

    handleFilterClick = (e) => {
        const { handleToggleColumn, intl } = this.props;

        if (this.state.selectedColumnsCount < 9 || !e.target.checked) {
            handleToggleColumn(e);

            this.updateNumberOfSelectedColumns(e.target.checked);
            return;
        }

        swal.fire({
            text: intl.formatMessage({
                id: 'table.preferences.too_many_columns.text',
            }),
            type: 'warning',
            confirmButtonText: intl.formatMessage({
                id: 'table.preferences.too_many_columns.btn',
            }),
        });
    };

    updateNumberOfSelectedColumns = (checked) => {
        if (checked) {
            this.setState((prevState) => {
                return {
                    selectedColumnsCount: prevState.selectedColumnsCount + 1,
                };
            });
        } else {
            this.setState((prevState) => {
                return {
                    selectedColumnsCount: prevState.selectedColumnsCount - 1,
                };
            });
        }
    };
    calculateCountOfSelected() {
        const columnsToShowInPreferences = this.props.columns.map((el) => {
            if (
                el.showInPreferences === undefined ||
                el.showInPreferences === true
            ) {
                return { id: el.id, show: el.show };
            }
        });
        const trueCount = columnsToShowInPreferences.reduce(
            (count, obj) => (obj.show ? count + 1 : count),
            0
        );
        this.setState({ selectedColumnsCount: trueCount });
    }

    render() {
        const {
            title,
            description,
            columns,
            hideIcon,
            isHidden = false,
        } = this.props;

        return (
            <div
                ref={this.setWrapperRef}
                id="preferences"
                className={
                    isHidden === true ? 'hidden-preferences' : 'preferences'
                }
            >
                {hideIcon === true && (
                    <React.Fragment>
                        <span
                            onClick={this.toggleOpen}
                            className="preferences-lnk"
                        >
                            <FormattedMessage id={title} />
                        </span>
                    </React.Fragment>
                )}

                {hideIcon !== true && (
                    <React.Fragment>
                        <img
                            className="preferences__icon"
                            onClick={this.toggleOpen}
                            src={preferencesGrey}
                            alt="preferences"
                        />
                    </React.Fragment>
                )}
                <CardPopupList
                    title={title}
                    description={description}
                    visible={this.state.isOpened}
                    handleClose={this.toggleOpen}
                >
                    {columns.map((column) => {
                        if (
                            column.showInPreferences === undefined ||
                            column.showInPreferences === true
                        ) {
                            return (
                                <li key={column.id}>
                                    <Input
                                        onClick={this.handleFilterClick}
                                        name={column.id}
                                        checked={column.show}
                                        label={column.preferencesLabel}
                                        filled
                                        type="checkbox"
                                        id={column.id}
                                        className="my-0"
                                    />
                                </li>
                            );
                        }
                        return null;
                    })}
                </CardPopupList>
            </div>
        );
    }
}

Preferences.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    columns: PropTypes.array,
    handleToggleColumn: PropTypes.func.isRequired,
};

export default injectIntl(Preferences);

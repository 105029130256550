import PropTypes from 'prop-types'
import React from 'react';

const ImagePromotion = ({data}) => {
    return (
        <div>
            <a href={data.url}>
                <img src={data.image} alt="promotion" width="80%"/>
            </a>
        </div>
    );
};

ImagePromotion.propTypes = {
  data: PropTypes.object.isRequired
};

export default ImagePromotion;

import React from 'react';
import Filters from './Filters';
import * as R from 'ramda';

import * as allergens from '../../../../../assets/images/allergens/allergens';
import * as allergensFr from '../../../../../assets/images/allergens/allergens_fr';
import history from '../../../../../history';
import * as qs from 'qs';
import { fetchProducts } from '../../../../../actions/masterTable';
import { connect } from 'react-redux';

const filtersData = {
    promotions: [
        {
            id: 1,
            name: 'gadget',
        },
        {
            id: 0,
            name: 'korting',
        },
    ],
};

let imagesLang =
    localStorage.getItem('lang') === 'nl' ? allergens : allergensFr;

let i = 1;

const mandatory = [
    { id: i++, icon: imagesLang.gluten, name: 'gluten' }, //1
    { id: i++, icon: imagesLang.wheat, name: 'wheat' },
    { id: i++, icon: imagesLang.rye, name: 'rye' },
    { id: i++, icon: imagesLang.barley, name: 'barley' },
    { id: i++, icon: imagesLang.oats, name: 'oats' },
    { id: i++, icon: imagesLang.spelled, name: 'spelled' },
    { id: i++, icon: imagesLang.khorasan_wheat, name: 'khorasan_wheat' },
    { id: i++, icon: imagesLang.crustaceans, name: 'crustaceans' },
    { id: i++, icon: imagesLang.eggs, name: 'eggs' },
    { id: i++, icon: imagesLang.fish, name: 'fish' },
    { id: i++, icon: imagesLang.groundnuts, name: 'groundnuts' },
    { id: i++, icon: imagesLang.soy, name: 'soy' },
    { id: i++, icon: imagesLang.milk, name: 'milk' },
    { id: i++, icon: imagesLang.scale_fruits, name: 'scale_fruits' },
    { id: i++, icon: imagesLang.almonds, name: 'almonds' },
    { id: i++, icon: imagesLang.hazelnuts, name: 'hazelnuts' },
    { id: i++, icon: imagesLang.walnuts, name: 'walnuts' },
    { id: i++, icon: imagesLang.cashew_nuts, name: 'cashew_nuts' },
    { id: i++, icon: imagesLang.pecans, name: 'pecans' },
    { id: i++, icon: imagesLang.brazil_nuts, name: 'brazil_nuts' },
    { id: i++, icon: imagesLang.pistachios, name: 'pistachios' },
    { id: i++, icon: imagesLang.macadamia_nuts, name: 'macadamia_nuts' },
    { id: i++, icon: imagesLang.celery, name: 'celery' },
    { id: i++, icon: imagesLang.mustard, name: 'mustard' },
    { id: i++, icon: imagesLang.sesame, name: 'sesame' },
    { id: i++, icon: imagesLang.sulfur, name: 'sulfur' },
    { id: i++, icon: imagesLang.lupine, name: 'lupine' },
    { id: i++, icon: imagesLang.mollusks, name: 'mollusks' }, //28
];

const staticFilters = {
    allergens: {
        mandatory,
        nonMandatory: [
            { id: i++, name: 'cattle' }, //29
            { id: i++, name: 'pig' },
            { id: i++, name: 'chicken' },
            { id: i++, name: 'corn' },
            { id: i++, name: 'cocoa' },
            { id: i++, name: 'yeast' },
            { id: i++, name: 'legumes' },
            { id: i++, name: 'cinnamon' },
            { id: i++, name: 'vanillin' },
            { id: i++, name: 'koreander' },
            { id: i++, name: 'screen_flowering_plants' },
            { id: i++, name: 'guanylates' },
            { id: i++, name: 'benzoates' },
            { id: i++, name: 'azo_dyes' },
            { id: i++, name: 'tartrazine' },
            { id: i++, name: 'aspartame' },
            { id: i++, name: 'cyclamates' },
            { id: i++, name: 'saccharin' },
            { id: i++, name: 'gallates' },
            { id: i++, name: 'bha_or_bht' },
            { id: i++, name: 'fructose' },
            { id: i++, name: 'sacharose' }, //48
        ],
    },
};

class FiltersContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filters: {},
            selected: {
                campaigns: [],
                promotions: [],
                allergens: [],
            },
            isOpened: false,
            selectedFiltersCount: 0,
            queryParams: qs.parse(history.location.search.slice(1)),
        };
    }

    componentDidMount() {
        this.selectProperFilterFromQuery();
        this.setState({
            filters: { ...filtersData, ...staticFilters },
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            prevProps.campaigns.length === 0 &&
            this.props.campaigns.length > 0
        ) {
            this.selectProperFilterFromQuery();
        }
    }

    handleFilterClick = (e) => {
        const { id, name, type } = e.target.dataset;

        if (e.target.checked) {
            this.selectFilter(id, name, type);
        } else {
            this.deselectFilter(id, type, name);
        }
    };

    handleRemoveFilter = (e) => {
        const { id, type, name } = e.target.dataset;

        this.deselectFilter(id, type, name, true);
    };

    clearSectionFilters = (type) => () => {
        this.setState({
            selected: {
                ...this.state.selected,
                ...(this.state.selected[type] = []),
            },
        });

        const queryParams = qs.parse(history.location.search.slice(1));
        for (let key in queryParams) {
            if (key.includes(`${type.charAt(0)}_`)) {
                delete queryParams[key];
            }
        }

        history.push({
            ...this.props.location,
            search: qs.stringify(queryParams),
        });

        this.setState({
            queryParams: queryParams,
        });
    };

    deselectFilter = (id, type, name, withFetch) => {
        const index = R.findIndex(R.propEq('id', Number.parseInt(id, 10)))(
            this.state.selected[type]
        );
        this.setState({
            selected: {
                ...(this.state.selected[type] = R.remove(
                    index,
                    1,
                    this.state.selected[type]
                )),
                ...this.state.selected,
            },
        });

        const queryParams = qs.parse(history.location.search.slice(1));
        if (type === 'campaigns') {
            queryParams['c_campaigns'] = R.without(
                id,
                queryParams['c_campaigns']
            );
        } else {
            delete queryParams[`${type.charAt(0)}_${name}`];
        }

        if (type === 'promotions') {
            queryParams['p_promotions'] = R.without(
                id,
                queryParams['p_promotions']
            );
        } else {
            delete queryParams[`${type.charAt(0)}_${name}`];
        }

        this.setState({
            queryParams: queryParams,
        });

        if (withFetch) {
            history.push({
                ...this.props.location,
                search: qs.stringify(queryParams),
            });

            this.props.fetchProducts({
                deliveryDate: this.props.deliveryDate,
                params: qs.parse(queryParams),
            });
        }
    };

    selectFilter = (id, name, type) => {
        const filter = {
            id: Number.parseInt(id, 10),
            type: type,
            name: name,
        };

        this.setState({
            selected: {
                ...(this.state.selected[type] = R.uniq(
                    R.prepend(filter, this.state.selected[type])
                )),
                ...this.state.selected,
            },
        });

        const queryParams = this.state.queryParams;

        if (type === 'campaigns') {
            if (queryParams['c_campaigns']) {
                queryParams['c_campaigns'] = [
                    ...new Set([...queryParams[`c_campaigns`], id.toString()]),
                ];
            } else {
                queryParams['c_campaigns'] = [id.toString()];
            }
        }
        if (type === 'promotions') {
            if (queryParams['p_promotions']) {
                queryParams['p_promotions'] = [
                    ...new Set([...queryParams[`p_promotions`], id.toString()]),
                ];
            } else {
                queryParams['p_promotions'] = [id.toString()];
            }
        } else {
            queryParams[`${type.charAt(0)}_${name}`] = 1;
        }

        this.setState({
            queryParams: { ...this.state.queryParams, ...queryParams },
        });
    };

    handleOpen = () => {
        this.setState({
            isOpened: true,
        });
    };

    handleClose = () => {
        this.setState({
            isOpened: false,
        });
    };

    applyFilters = () => {
        const { location, deliveryDate, fetchProducts } = this.props;
        const { queryParams } = this.state;

        history.push({
            ...location,
            search: qs.stringify(queryParams),
        });

        fetchProducts({
            deliveryDate: deliveryDate,
            params: qs.parse(queryParams),
        });

        this.handleClose();
    };

    selectProperFilterFromQuery = () => {
        const queryParams = qs.parse(history.location.search.slice(1));

        for (let key in queryParams) {
            if (key.includes('a_')) {
                const allergen = this.findAllergenIdByName(key.substring(2));
                allergen &&
                    this.selectFilter(allergen.id, allergen.name, 'allergens');
            }

            //@TODO Implement in future when API will be ready
            if (key.includes('p_')) {
                queryParams[key].forEach((promotionType) => {
                    const id = Number.parseInt(promotionType, 10);
                    const promotion = this.findPromotionById(id);
                    promotion &&
                        this.selectFilter(id, promotion.name, 'promotions');
                });
            }
            if (key.includes('c_')) {
                queryParams[key].forEach((campaignId) => {
                    const id = Number.parseInt(campaignId, 10);
                    const campaign = this.findCampaignById(id);
                    campaign &&
                        this.selectFilter(id, campaign.name, 'campaigns');
                });
            }
        }
    };

    findAllergenIdByName = (name) => {
        const mandatory = R.find(R.propEq('name', name))(
            staticFilters.allergens.mandatory
        );
        const nonMandatory = R.find(R.propEq('name', name))(
            staticFilters.allergens.nonMandatory
        );

        if (mandatory || nonMandatory) {
            return { ...mandatory, ...nonMandatory };
        }

        return null;
    };

    findCampaignById = (id) => {
        return R.find(R.propEq('id', id))(this.props.campaigns);
    };

    findPromotionById = (id) => {
        return R.find(R.propEq('id', id))(filtersData.promotions);
    };

    render() {
        return (
            <Filters
                campaigns={this.props.campaigns}
                promotions={
                    this.state.filters.promotions
                        ? R.splitEvery(1, this.state.filters.promotions)
                        : this.state.filters.promotions
                }
                allergens={this.state.filters.allergens}
                selected={this.state.selected}
                isOpened={this.state.isOpened}
                handleFilterClick={this.handleFilterClick}
                handleRemoveFilter={this.handleRemoveFilter}
                handleOpen={this.handleOpen}
                handleClose={this.handleClose}
                clearSectionFilters={this.clearSectionFilters}
                applyFilters={this.applyFilters}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        campaigns: state.campaigns.data,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

export default connect(mapStateToProps, { fetchProducts })(FiltersContainer);

FiltersContainer.defaultProps = {
    campaigns: [],
};

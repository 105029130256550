import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-table/react-table.css';
import 'react-toastify/dist/ReactToastify.css';

import '../assets/stylesheets/main.css';

import MasterTableContainer from './Pages/MasterTable/MasterTableContainer';
import LoginContainer from './Pages/Security/Login/LoginContainer';
import RequestPasswordContainer from './Pages/Security/PasswordRecovery/Request/RequestPasswordContainer';
import CheckEmail from './Pages/Security/PasswordRecovery/check-email/CheckEmail';
import CartPageContainer from './Pages/CartPage/CartPageContainer';
import CampaignContainer from './Pages/Campaign/CampaignContainer';
import NotificationsContainer from './Pages/Notifications/NotificationsContainer';
import AllergensContainer from './Pages/Allergens/AllergensContainer';
import RecipesCategoriesContainer from './Pages/Recipes/RecipesCategories/RecipesCategoriesContainer';
import RecipesListContainer from './Pages/Recipes/RecipesList/RecipesListContainer';
import RecipeDetailsContainer from './Pages/Recipes/RecipeDetails/RecipeDetailsContainer';
import DownloadsContainer from './Pages/Downloads/DownloadsContainer';
import MyAccountContainer from './Pages/MyAccount/MyAccountContainer';
import DeliveryContainer from './Pages/Delivery/DeliveryContainer';
import DeliveryDatepickerContainer from './Datepicker/DeliveryDatepickerContainer';
import ProtectedRoute from './Routes/ProtectedRoute';
import SecurityRoute from './Routes/SecurityRoute';
import UnprotectedRoute from './Routes/UnprotectedRoute';
import DashboardContainer from './Dashboard/DashboardContainer';
import NewPasswordResetTokenContainer from './Pages/Security/NewPassword/ResetToken/NewPasswordResetTokenContainer';
import NewPasswordAccessTokenContainer from './Pages/Security/NewPassword/AccessToken/NewPasswordAccessTokenContainer';
import PromotionsContainer from './Pages/Promotions/PromotionsContainer';
import * as PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hideFilterable } from '../actions/table';
import NoInternetConnection from './NoInternetConnection/NoInternetConnectionContainer';
import CookieInformation from './CookieInformation/CookieInformation';
import TokenAuth from './Security/TokenAuth';
import RecipesFavouritesListContainer from './Pages/Recipes/RecipesFavouritesList/RecipesFavouritesListContainer';
import CookiePolicy from './Pages/CookiePolicy/CookiePolicy';
import ProductDetailsContainer from './ProductDetails/ProductDetailsContainer';
import RecipesSearchContainer from './Pages/Recipes/RecipesSearch/RecipesSearchContainer';
import DisclaimerPage from './Pages/DisclaimerPage/DisclaimerPage';
import FairContainer from './Pages/Fair/FairContainer';
import Oscart from './Pages/Oscart/Oscart';
import CacheBuster from '../cacheBuster';
import packageJson from '../../package.json';
import { addFrankyWidget, destroyFrankyWidget } from '../actions/frankyWidget';
import ResponsibleDisclosurePolicy from './Pages/ResponsibleDisclosurePolicy/ResponsibleDisclosurePolicy';
import NoveltyContainer from './Pages/Novelty/NoveltyContainer';

ReactGA.initialize('UA-138527855-1');

const tagManagerArgs = {
    gtmId: 'GTM-TN87V2G',
};

TagManager.initialize(tagManagerArgs);

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pathname: this.props.history.location.pathname,
        };

        this.props.history.listen((location) => {
            ReactGA.pageview(location.pathname + location.search);
            //@TODO Implement checking if token is valid on route change
            if (location.pathname !== this.state.pathname) {
                this.props.hideFilterable();
                window.scrollTo(0, 0);
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.pathname !== this.props.history.location.pathname) {
            this.setState({
                pathname: this.props.history.location.pathname,
            });
        }
    }

    render() {
        let { location } = this.props;
        const localStorageVersion = localStorage.getItem('version');

        if (
            location.pathname !== '/products' &&
            location.pathname !== '/cart'
        ) {
            destroyFrankyWidget();
        } else {
            addFrankyWidget();
        }

        return (
            <CacheBuster>
                {({ loading, refreshCacheAndReload }) => {
                    if (
                        !loading &&
                        localStorageVersion !== packageJson.version
                    ) {
                        localStorage.setItem('version', packageJson.version);
                        refreshCacheAndReload();
                    }
                    return (
                        <div>
                            <Switch>
                                <SecurityRoute
                                    exact
                                    location={location}
                                    path="/"
                                    component={LoginContainer}
                                    routeType={Route}
                                />
                                <SecurityRoute
                                    exact
                                    location={location}
                                    path="/security/password-recovery/request"
                                    component={RequestPasswordContainer}
                                    routeType={Route}
                                />
                                <SecurityRoute
                                    exact
                                    location={location}
                                    path="/security/password-recovery/check-email"
                                    component={CheckEmail}
                                    routeType={Route}
                                />
                                <SecurityRoute
                                    exact
                                    location={location}
                                    path="/security/new-password/:token"
                                    component={NewPasswordResetTokenContainer}
                                    routeType={Route}
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/security/force-new-password"
                                    component={NewPasswordAccessTokenContainer}
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/dashboard"
                                    component={DashboardContainer}
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/products"
                                    component={MasterTableContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/promotions"
                                    component={PromotionsContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/cart"
                                    component={CartPageContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/fair"
                                    component={FairContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/campaign"
                                    component={CampaignContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/notifications"
                                    component={NotificationsContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/allergens"
                                    component={AllergensContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/recipes/search"
                                    component={RecipesSearchContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/recipes/categories"
                                    component={RecipesCategoriesContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/recipes/favourites"
                                    component={RecipesFavouritesListContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/recipes/:category"
                                    component={RecipesListContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/recipe/:slug"
                                    component={RecipeDetailsContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    location={location}
                                    path="/downloads/:folderId?"
                                    component={DownloadsContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    location={location}
                                    path="/my-account"
                                    component={MyAccountContainer}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    location={location}
                                    path="/deliveries"
                                    component={DeliveryContainer}
                                    withHeader
                                    withFooter
                                />
                                <UnprotectedRoute
                                    location={location}
                                    path="/cookie-policy"
                                    component={CookiePolicy}
                                    withHeader
                                    withFooter
                                />
                                <UnprotectedRoute
                                    location={location}
                                    path="/responsible-disclosure-policy"
                                    component={ResponsibleDisclosurePolicy}
                                    withHeader
                                    withFooter
                                />
                                <UnprotectedRoute
                                    location={location}
                                    path="/disclaimer"
                                    component={DisclaimerPage}
                                    withHeader
                                    withFooter
                                />
                                <ProtectedRoute
                                    location={location}
                                    path="/oscart"
                                    component={Oscart}
                                    withHeader
                                    withFooter
                                    fluid
                                />
                                <ProtectedRoute
                                    exact
                                    location={location}
                                    path="/novelty"
                                    component={NoveltyContainer}
                                    withHeader
                                    withFooter
                                />
                                <Route
                                    location={location}
                                    path="/auth/token/:token/:refreshToken"
                                    component={TokenAuth}
                                />
                            </Switch>
                            {this.props.isAuthenticated && (
                                <React.Fragment>
                                    <ProductDetailsContainer />
                                    <DeliveryDatepickerContainer
                                        global={true}
                                    />
                                </React.Fragment>
                            )}
                            <NoInternetConnection />
                            <CookieInformation />
                            <ToastContainer autoClose={4000} />
                        </div>
                    );
                }}
            </CacheBuster>
        );
    }
}

App.propTypes = { location: PropTypes.any };

const mapStateToProps = (state) => {
    return {
        isAuthenticated: !!state.security.access_token,
    };
};

export default connect(mapStateToProps, { hideFilterable })(App);

import request from './request';

const Products = {
    getProducts: ({ params }) => {
        return request({
            url: `/api/product/list`,
            method: 'GET',
            params: params,
        });
    },
    getProduct: ({ id }) => {
        return request({
            url: `/api/product/${id}`,
            method: 'GET',
        });
    },
    getTopList: () => {
        return request({
            url: '/api/product/toplist',
            method: 'GET',
        });
    },
    getMyListPDF: ({ params }) => {
        return request({
            url: `/api/product/pdf`,
            method: 'GET',
            responseType: 'arraybuffer',
            params: params,
        });
    },
    getMyListExcel: ({ params }) => {
        return request({
            url: `/api/product/excel`,
            method: 'GET',
            responseType: 'arraybuffer',
            params: params,
        });
    },
    getProductSpecificationPdf: (id) => {
        return request({
            url: `/api/product/allergensheet/${id}`,
            method: 'GET',
            responseType: 'arraybuffer',
        });
    },
    getAllCategories: () => {
        return request({
            url: '/api/product/categories',
            method: 'GET',
        });
    },
    getOrderHistory: (id) => {
        return request({
            url: `/api/product/order-history/${id}`,
            method: 'GET',
        });
    },
    getAllergenListPdf: () => {
        return request({
            url: `/api/product/allergen-list/pdf`,
            method: 'GET',
            responseType: 'arraybuffer',
        });
    },
    removeFromAssortiment: ({ id, params }) => {
        return request({
            url: `/api/product/remove-from-assortiment/${id}`,
            method: 'DELETE',
            params: params,
        });
    },
};

export default Products;

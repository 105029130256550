import PropTypes from 'prop-types';
import React from 'react';
import logo from '../../assets/images/logo.svg';
import Footer from '../Footer/Footer';
import { Col, Container, Dropdown, DropdownToggle, Row } from 'mdbreact';
import {
    homeGrey,
    homeWhite,
    deliveryWhite,
    deliveryGrey,
    allergensGrey,
    allergensWhite,
    campaignsGrey,
    campaignsWhite,
    packagingGrey,
    packagingWhite,
    recipesGrey,
    recipesWhite,
    downloadGrey,
    downloadWhite,
    promotionsWhite,
    promotionsGrey,
    pageWhite,
    pageGrey,
    tvIconWhite,
    tvIconGrey,
    newReleaseWhite,
    newReleaseGrey,
} from '../../assets/images/icons';
import AccountDropdownMenu from '../AccountDropdownMenu';
import SectionButtonContainer from './components/SectionButton/SectionButtonContainer';
import NotificationContainer from './components/Notification/NotificationContainer';
import PromotionalModalContainer from '../PromotionalModal/PromotionalModalContainer';
import Spinner from '../Utils/Spinner';
import CustomersContainer from '../Customers/CustomersContainer';
import { FormattedMessage } from 'react-intl';

const Dashboard = ({
    logout,
    userDetails,
    notifications,
    locale,
    isCampaignActive,
}) => {
    return (
        <div className="dashboard container-fluid">
            <Row>
                <Col>
                    <img className="dashboard__logo" src={logo} alt="logo" />
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col sm="3">
                    <CustomersContainer userDetails={userDetails} />
                    <Dropdown className="dashboard__account-switch account-dropdown-menu">
                        <DropdownToggle nav caret>
                            <span className="dashboard__account-switch__label">
                                <FormattedMessage id="account.label" />:{' '}
                                <span className="dashboard__account-switch__label__account">
                                    {!userDetails ? (
                                        <Spinner
                                            width={15}
                                            height={15}
                                            className="spinner--white"
                                        />
                                    ) : (
                                        userDetails.username
                                    )}
                                </span>
                            </span>
                        </DropdownToggle>
                        {userDetails && (
                            <AccountDropdownMenu
                                logout={logout}
                                accountList={[
                                    {
                                        id: userDetails.id,
                                        username: userDetails.username,
                                    },
                                ]}
                                selected={userDetails.id}
                            />
                        )}
                    </Dropdown>
                </Col>
            </Row>
            {notifications && (
                <Container className="container-smaller dashboard__notifications-container">
                    {notifications.map((notification) => {
                        return (
                            <NotificationContainer
                                key={notification.id}
                                id={notification.id}
                                text={notification.notification}
                                url={notification.link}
                            />
                        );
                    })}
                </Container>
            )}
            <div className="justify-content-md-center dashboard__sections">
                <Container className="container-smaller">
                    <Row>
                        <Col className="col-11 dashboard__section__wrapper">
                            <SectionButtonContainer
                                to="/products"
                                label="dashboard.section.order"
                                icon={homeWhite}
                                iconHover={homeGrey}
                            />
                            <SectionButtonContainer
                                to="/deliveries"
                                label="dashboard.section.delivers"
                                icon={deliveryWhite}
                                iconHover={deliveryGrey}
                            />
                            <SectionButtonContainer
                                to="/promotions"
                                label="dashboard.section.promotions"
                                icon={promotionsWhite}
                                iconHover={promotionsGrey}
                            />
                            <SectionButtonContainer
                                to="/novelty"
                                label="dashboard.section.novelty"
                                icon={newReleaseWhite}
                                iconHover={newReleaseGrey}
                            />
                            <SectionButtonContainer
                                to={
                                    locale === 'nl'
                                        ? 'https://www.franky-quality.be/folders'
                                        : 'https://www.franky-quality.be/fr/brochures'
                                }
                                isExternal
                                label="dashboard.section.folders"
                                icon={pageWhite}
                                iconHover={pageGrey}
                            />
                            {/*<SectionButtonContainer
                                to="/downloads"
                                label="dashboard.section.downloads"
                                icon={downloadWhite}
                                iconHover={downloadGrey}
                            />*/}
                            <SectionButtonContainer
                                to="/allergens"
                                label="dashboard.section.allergens"
                                icon={allergensWhite}
                                iconHover={allergensGrey}
                            />
                            <SectionButtonContainer
                                to="/recipes/categories"
                                label="dashboard.section.recipes"
                                icon={recipesWhite}
                                iconHover={recipesGrey}
                            />
                            <SectionButtonContainer
                                to="/campaign"
                                label="dashboard.section.campaigns"
                                icon={campaignsWhite}
                                iconHover={campaignsGrey}
                                isDisabled={!isCampaignActive}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer inverted />
            <PromotionalModalContainer />
        </div>
    );
};

Dashboard.propTypes = {
    isCampaignActive: PropTypes.bool,
    locale: PropTypes.string,
    logout: PropTypes.func,
    notifications: PropTypes.array,
    userDetails: PropTypes.object,
};

export default Dashboard;

import {loadingStart, loadingStop} from "../actions/loadingState";
import {call, put} from "redux-saga/effects";
import Products from "../services/api/Products";
import {productsFetched, productsFetchedFailure} from "../actions/masterTable";

export function* fetchProductsSaga(action) {
    yield put(loadingStart());
    try {
        const response = yield call(Products.getProducts, action.data);

        yield put(productsFetched({
            products: response.data,
            total: response.headers["x-total-count"],
            hasMyList: response.headers["x-has-my-list"]
        }));
    } catch (err) {
        yield put(productsFetchedFailure(err.message))
    }
    yield put(loadingStop());
}
import PropTypes from 'prop-types';
import React from 'react';
import Search from '../../../../Search/Search';
import { injectIntl } from 'react-intl';
import { fetchProducts } from '../../../../../actions/masterTable';
import { connect } from 'react-redux';
import { fetchProductCategories } from '../../../../../actions/routines';
import * as qs from 'qs';
import history from '../../../../../history';

class SearchContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedValue: null,
        };
    }

    componentDidMount() {
        if (!this.props.withoutCategories) {
            this.props.fetchProductCategories();
        }

        const queryParams = qs.parse(history.location.search.slice(1));
        if (queryParams['s_category']) {
            this.setState({
                selectedValue: queryParams['s_category'],
            });
        }
    }

    search = (params) => {
        const searchCategory = this.state.selectedValue;

        if (searchCategory) {
            params['s_category'] = searchCategory;
        } else {
            delete params['s_category'];
        }

        history.push({
            ...this.props.location,
            search: qs.stringify(params),
        });

        this.props.fetchProducts({
            deliveryDate: this.props.deliveryDate,
            params: params,
        });
    };

    getSelectedValue = (e) => {
        this.setState({
            selectedValue: e[0] === 'all-categories' ? null : e[0],
        });
    };

    render() {
        const { intl, loading, categories, withoutCategories } = this.props;

        return (
            <Search
                withSelect={!withoutCategories}
                loading={loading}
                options={categories}
                selectedValue={this.state.selectedValue}
                getSelectedValue={this.getSelectedValue}
                placeholder={intl.formatMessage({
                    id: 'table.search.placeholder',
                })}
                label="delivery.search.btn"
                onSubmit={this.search}
            />
        );
    }
}

SearchContainer.propTypes = {
    intl: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {
        loading: state.loadingState.loading,
        categories: state.productCategories.data,
        deliveryDate: state.deliveryDatepicker.deliveryDate,
    };
};

export default injectIntl(
    connect(mapStateToProps, { fetchProducts, fetchProductCategories })(
        SearchContainer
    )
);

import React from 'react';
import Quantity from './Quantity';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addItemCart } from '../../actions/routines';
import debounce from 'lodash.debounce';
import swal from 'sweetalert2';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Context } from '../context/context';

class QuantityContainerForCart extends React.Component {
    constructor(props) {
        super(props);

        const { quantity, itemsInPackage, orderAsPackage } = this.props;

        this.state = {
            value: quantity,
            itemsInPackage: orderAsPackage ? itemsInPackage : 1,
            previousValue: 0,
            isMaxOrderQuantity: false,
        };

        this.ref = null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.productId !== this.props.productId) {
            //Update quantity when deleting row in table in cart
            this.setState({
                value: this.props.quantity,
                previousValue: this.props.quantity,
                itemsInPackage: this.props.orderAsPackage
                    ? this.props.itemsInPackage
                    : 1,
            });
        }
    }

    setRef = (node) => {
        this.ref = node;
    };

    handleIncrement = (draftCartList, draftCartListChange) => {
        const { itemsInPackage } = this.state;
        let itemAmount;
        this.setState((prevState) => {
            const updatedCartItems = draftCartList.map((item) => {
                if (item.product.id === this.props.productId) {
                    itemAmount = item.amount + 1;
                    return { ...item, amount: itemAmount };
                }
                return item;
            });

            if (
                itemAmount > this.props.product.max_order_quantity &&
                this.props.product.max_order_quantity > 0
            ) {
                this.setState({
                    isMaxOrderQuantity: true,
                });

                return {
                    value: this.getProperValue(prevState.value),
                };
            }

            draftCartListChange(updatedCartItems);
            return {
                value: itemAmount,
            };
        });
    };

    handleDecrement = (draftCartList, draftCartListChange) => {
        let itemAmount;

        this.setState((prevState) => {
            // this.addItemToCart(value);

            const updatedCartItems = draftCartList.map((item) => {
                if (item.product.id === this.props.productId) {
                    itemAmount = item.amount - 1;
                    if (itemAmount <= 0) {
                        itemAmount = 1;
                    }
                    return { ...item, amount: itemAmount };
                }
                return item;
            });

            if (itemAmount <= this.props.product.max_order_quantity) {
                this.setState({
                    isMaxOrderQuantity: false,
                });
            }

            draftCartListChange(updatedCartItems);
            return {
                value: itemAmount,
            };
        });
    };

    handleChange = (e, draftCartList, draftCartListChange) => {
        const { previousValue } = this.state;
        const value = this.getProperValue(e.target.value);

        if (
            value > this.props.product.max_order_quantity &&
            this.props.product.max_order_quantity > 0
        ) {
            this.setState({
                isMaxOrderQuantity: true,
            });
        } else {
            this.setState({
                isMaxOrderQuantity: false,
            });
        }

        this.setState({
            value,
            previousValue,
        });

        const updatedCartItems = draftCartList.map((item) => {
            if (item.product.id === this.props.productId) {
                return { ...item, amount: Number(e.target.value) };
            }
            return item;
        });

        draftCartListChange(updatedCartItems);
    };

    getProperValue = (value, itemsInPackage) => {
        let v = Number.parseInt(value, 10) || 0;

        if (itemsInPackage && v % itemsInPackage !== 0) {
            return itemsInPackage * Math.ceil(v / itemsInPackage);
        }

        if (v <= 0) {
            return 0;
        }

        return v;
    };

    addItemToCartOnBlur = (e) => {
        const { itemsInPackage, intl, orderAsPackage } = this.props;

        if (e.target.value % itemsInPackage === 0) {
            this.addItemToCart(e.target.value);
            return;
        }

        this.addItemToCart(this.getProperValue(e.target.value, itemsInPackage));

        this.setState({
            value: this.getProperValue(
                e.target.value,
                this.state.itemsInPackage
            ),
        });

        if (orderAsPackage) {
            swal.fire({
                text: intl.formatMessage(
                    { id: 'quantity.order_as_package.text' },
                    { itemsInPackage: itemsInPackage }
                ),
                type: 'warning',
                confirmButtonText: intl.formatMessage({
                    id: 'quantity.order_as_package.btn',
                }),
            });
        } else {
            swal.fire({
                text: intl.formatMessage(
                    { id: 'quantity.order_as_package_false.text' },
                    { itemsInPackage: itemsInPackage }
                ),
                type: 'warning',
                confirmButtonText: intl.formatMessage({
                    id: 'quantity.order_as_package_false.btn',
                }),
            });
        }
    };

    addItemToCart = debounce(async (amount) => {
        this.props.addItemCart({
            productId: this.props.productId,
            amount: amount,
        });
    }, 500);

    goToNextInputKeyPress = (e) => {
        const { tabIndex } = this.props;
        if (['Enter', 'ArrowDown'].includes(e.key)) {
            const input = document.querySelector(
                '[tabIndex="' + (tabIndex + 1) + '"]'
            );
            if (input) {
                input.focus();
            }
        } else if (e.key === 'ArrowUp') {
            const input = document.querySelector(
                '[tabIndex="' + (tabIndex - 1) + '"]'
            );
            if (input) {
                input.focus();
            }
        }
    };

    getValueOfQuantityFromDraft = (draftCartList) => {
        let exactProduct = draftCartList.find(
            (el) => el.product.id === this.props.productId
        );
        return exactProduct?.amount;
    };

    render() {
        const { product } = this.props;

        if (product.is_available === false) {
            return (
                <div className="quantity">{product.not_available_message}</div>
            );
        }
        return (
            <Context.Consumer>
                {({ draftCartList, draftCartListChange }) => (
                    <div>
                        <Quantity
                            value={this.getValueOfQuantityFromDraft(
                                draftCartList
                            )}
                            requestForInputBlur={false}
                            handleChange={(e) =>
                                this.handleChange(
                                    e,
                                    draftCartList,
                                    draftCartListChange
                                )
                            }
                            handleIncrement={() =>
                                this.handleIncrement(
                                    draftCartList,
                                    draftCartListChange
                                )
                            }
                            handleDecrement={() =>
                                this.handleDecrement(
                                    draftCartList,
                                    draftCartListChange
                                )
                            }
                            tabIndex={this.props.tabIndex}
                            // addItemToCart={this.addItemToCartOnBlur}
                            setRef={this.setRef}
                            goToNextInputKeyPress={this.goToNextInputKeyPress}
                        />
                        {this.state.isMaxOrderQuantity > 0 && (
                            <div style={{ whiteSpace: 'normal' }}>
                                <FormattedMessage
                                    id="cart.limit_max_quantity"
                                    values={{
                                        maxOrderQuantity:
                                            product.max_order_quantity,
                                        maxOrderUnit: product.max_order_unit,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
            </Context.Consumer>
        );
    }
}

QuantityContainerForCart.propTypes = {
    addItemCart: PropTypes.func,
    itemsInPackage: PropTypes.number,
    orderAsPackage: PropTypes.bool,
    productId: PropTypes.number,
    quantity: PropTypes.number,
    tabIndex: PropTypes.number,
};

const mapStateToProps = (state) => {
    return {
        isOpen: state.deliveryDatepicker.isDeliveryDatepickerOpen,
        products: state.masterTable.products,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(
            {
                addItemCart,
            },
            dispatch
        ),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(QuantityContainerForCart));

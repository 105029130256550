import React from "react";
import PropTypes from 'prop-types'
import {FormattedMessage, injectIntl} from "react-intl";
import {Tooltip} from "mdbreact";

const FutureDeliveries = ({inDelivery}) => {

    if (!inDelivery || inDelivery.length === 0) {
        return null
    }

    const renderTooltipContent = () => {
        return (
            <div className="future-delivery__popover">
                <table>
                    <thead>
                        <tr className="row-head">
                            <th><FormattedMessage id="in_delivery.label" /></th>
                            <th><FormattedMessage id="in_delivery.amount" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {inDelivery.map(product => (
                            <tr>
                                <td>{ product.deliveryDate }</td>
                                <td>{ product.amount }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    return (
        <Tooltip
            className="future-delivery"
            tag="span"
            placement="left"
            componentClass="future-delivery__badge"
            tooltipContent={renderTooltipContent()}
        >
            <span className="material-icons-outlined">local_shipping</span>
        </Tooltip>
    );
};


FutureDeliveries.propTypes = {
    id: PropTypes.number,
    inDelivery: PropTypes.array,
    intl: PropTypes.object
};

export default injectIntl(FutureDeliveries);

import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'mdbreact';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Retour from './Retour';
import orderStatus from '../../../../const/orderStatus.json';

class DeliveryDetailsData extends React.Component {
    componentDidMount() {
        const { delivery } = this.props;
    }

    render() {
        const { delivery, title, deliveryAt } = this.props;

        return (
            <div className="delivery__details__data">
                {deliveryAt && (
                    <p className="delivery__details__data__title">
                        <FormattedMessage id={title} />{' '}
                        {moment(deliveryAt).format('DD/MM/YYYY')}
                    </p>
                )}
                <div>
                    <Row className="delivery__details__data__table__header">
                        <Col size="1">
                            <FormattedMessage id="delivery.details.art_nr" />
                        </Col>
                        <Col size="1">
                            <FormattedMessage id="delivery.details.quantity" />
                        </Col>
                        <Col size="2">
                            <FormattedMessage id="delivery.details.parcel_number" />
                        </Col>
                        <Col size="2">
                            <FormattedMessage id="delivery.details.expiry_date" />
                        </Col>
                        <Col size="4">
                            <FormattedMessage id="delivery.details.product_name" />
                        </Col>
                        <Col size="2">
                            <FormattedMessage id="delivery.details.delivery_date" />
                        </Col>
                    </Row>
                    {delivery.map((data, index) => (
                        <Row style={{ position: 'relative' }} key={index}>
                            <Col size="1">{data.product.article_nr}</Col>
                            <Col size="1">{data.amount}</Col>
                            <Col size="2">{data.parcel_nr}</Col>
                            <Col size="2">
                                {data.expiry_date
                                    ? moment(data.expiry_date).format(
                                          'DD/MM/YYYY'
                                      )
                                    : ''}
                            </Col>
                            <Col size="4">{data.product.name}</Col>
                            <Col size="2">
                                {moment(data.delivery_date).format(
                                    'DD/MM/YYYY'
                                )}
                            </Col>
                            {data.is_available_retour && (
                                <Retour
                                    retour={this.props.retour}
                                    resetRetourError={
                                        this.props.resetRetourError
                                    }
                                    orderNumber={this.props.orderNumber}
                                    orderItem={data}
                                    onUpdateOrderStatus={
                                        this.props.handleUpdateOrderStatus
                                    }
                                />
                            )}
                            {
                                <div
                                    style={{
                                        position: 'absolute',
                                        right: '-30px',
                                        height: '20px',
                                        top: '0',
                                        paddingTop: 0,
                                        paddingBottom: 0,
                                    }}
                                >
                                    {data.status}
                                </div>
                            }
                        </Row>
                    ))}
                </div>
            </div>
        );
    }
}

DeliveryDetailsData.propTypes = {
    delivery: PropTypes.array,
    title: PropTypes.string,
    deliveryAt: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        userDetails: state.userDetails.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(DeliveryDetailsData));

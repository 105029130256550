import PropTypes from 'prop-types'
import React from 'react';
import {DropdownItem, DropdownMenu} from "mdbreact";
import {accountGrey, accountWhite, signOutGrey, signOutWhite} from "../assets/images/icons";
import DropdownMenuItem from "./Dropdown/DropdownMenuItem";
import {Link} from "react-router-dom";

const AccountDropdownMenu = ({logout, accountList, selected}) => (
    <DropdownMenu>
        {
            accountList.map((account) => (
                <Link key={account.id} to="#">
                    <DropdownItem>
                        <span className={`text-uppercase ${account.id === selected ? 'font-weight-normal' : ''}`}>
                            {account.username}
                        </span>
                    </DropdownItem>
                </Link>
            ))
        }
        <hr/>
        <DropdownMenuItem label="account.dropdown.my_account" href="/my-account" icon={accountGrey} iconHover={accountWhite} />
        <DropdownMenuItem label="account.dropdown.sign_out" href="#" onClick={logout} icon={signOutGrey} iconHover={signOutWhite} />
    </DropdownMenu>
);

AccountDropdownMenu.propTypes = {
  accountList: PropTypes.array,
  logout: PropTypes.func,
  selected: PropTypes.number
};

AccountDropdownMenu.defaultProps = {
  accountList: []
};

export default AccountDropdownMenu;
import request from './request';
import {fetchFairPoints} from "../../actions/routines";
import store from '../../store';

const Fair = {
    getProducts: (params) => {
        return request({
            url: `/api/fair/product`,
            method: 'GET',
            params: params
        });
    },
    getSuppliers: (params) => {
        return request({
            url: `/api/fair/supplier`,
            method: 'GET',
            params: params
        });
    },
    getPointsInfo: () => {
        return request({
            url: `/api/fair/points-info`,
            method: 'GET'
        });
    },
    getBaskets: () => {
        return request({
            url: `/api/fair-cart/baskets`,
            method: 'GET'
        });
    },
    getFairProductsList: () => {
        return request({
            url: `/api/fair-cart/products`,
            method: 'GET'
        });
    },
    addProduct: (week, productId, amount) => {
        // find/replace all `/` with `-`
        const weekFormat = week.replace(new RegExp('/', 'g'), '-');

        return request({
            url: `/api/fair-cart/${weekFormat}/${productId}/${amount}`,
            method: 'PUT',
        })
            .then(() => {
                store.dispatch(fetchFairPoints());
            })
        ;
    },
    completeCart: () => {
        return request({
            url: `/api/fair-cart/complete`,
            method: 'PATCH',
        });
    },
    getPdf: (params) => {
        return request({
            url: `/api/fair/pdf`,
            method: 'GET',
            responseType: 'arraybuffer',
            params
        });
    },
    getDynamicWeeksData: () => {
        return request({
            url: `/api/fair/dynamic-week/data`,
            method: 'GET'
        });
    },
    addDynamicWeek: ({week, year}) => {
        return request({
            url: `/api/fair/dynamic-week`,
            method: 'POST',
            data: {
                week,
                year,
            },
        });
    },
};

export default Fair;
import PropTypes from 'prop-types'
import React from "react";
import TitleHeader from "../../components/TitleHeader";
import recipesGrey from "../../../../assets/images/recipes-grey.svg";
import ItemBox from "../../components/ItemBox/ItemBox";
import MasonryInfiniteScroller from 'react-masonry-infinite';
import {Spinner} from "mdbreact";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import SearchContainer from "./SearchContainer";

const RecipesList = ({category, recipes, hasMore, isLoading, loadMore, toggleFavourites, title, titleValues, withSearch, onReSearch}) => {
    return (
        <div className={`recipes recipes__list ${withSearch ? 'recipes__list--with-search' : '' }`}>
            <TitleHeader
                icon={recipesGrey}
                title={title}
                values={titleValues}
            >
                {withSearch && <SearchContainer onReSearch={onReSearch}/>}
                <Link to="/recipes/categories" className="recipes__list__go-back">
                    <FormattedMessage id="recipes.list.back_url"/>
                </Link>
                {category &&
                <p>
                    <Link to="/recipes/favourites" className="recipes__list__favourites">
                        <i className="favourite-icon__icon favourite-icon--is-favourite"/>
                        <FormattedMessage id="recipes.favourites_url"/>
                    </Link>
                </p>
                }
            </TitleHeader>

            <MasonryInfiniteScroller
                hasMore={!isLoading && hasMore}
                loadMore={loadMore}
                sizes={[
                    { columns: 3, gutter: 20 },
                    { mq: '1200px', columns: 4, gutter: 20 }
                ]}
                className="mb-5 mx-auto"
            >
                {
                    recipes.map(recipe =>
                        <ItemBox
                            key={recipe.id}
                            id={recipe.id}
                            image={recipe.image}
                            text={recipe.title}
                            link={`/recipe/${recipe.slug}`}
                            isFavourite={recipe.isFavourite}
                            toggleFavourites={toggleFavourites}
                        />
                    )
                }
            </MasonryInfiniteScroller>

            {isLoading && <div className="flex-center"> <Spinner className="brand-color"/></div>}
        </div>
    );
};

RecipesList.propTypes = {
  category: PropTypes.string,
  hasMore: PropTypes.bool,
  isLoading: PropTypes.any,
  loadMore: PropTypes.func,
  recipes: PropTypes.array,
  title: PropTypes.string,
  titleValues: PropTypes.object,
  toggleFavourites: PropTypes.func
};

RecipesList.defaultProps = {
    title: "recipes.list.title",
    titleValues: {category: ''},
    withSearch: false
};


export default RecipesList;

import {
    downloadWhite, homeWhite, allergensWhite, campaignsWhite, recipesWhite, tvIconWhite} from '../assets/images/icons';
import deliveryWhite from "../assets/images/delivery-white.svg";
import promotionsWhite from "../assets/images/promotions-white.svg";
import exhibitionModuleWhite from "../assets/images/exhibition-module-white.svg";

const getInformationsAboutActivePage = (path) => {
    const data = {};

    switch (path) {
        case "/products":
            data.label = "menu.order";
            data.icon = homeWhite;
            break;
        case "/campaign":
            data.label = "menu.campaigns";
            data.icon = campaignsWhite;
            break;
        case "/allergens":
            data.label = "menu.allergens";
            data.icon = allergensWhite;
            break;
        case (path.match('/downloads') || {}).input:
            data.label = "menu.downloads";
            data.icon = downloadWhite;
            break;
        case (path.match('/recipe') || {}).input:
            data.label = "menu.recipes";
            data.icon = recipesWhite;
            break;
        case "/deliveries":
            data.label = "menu.delivers";
            data.icon = deliveryWhite;
            break;
        case "/promotions":
            data.label = "menu.promotions";
            data.icon = promotionsWhite;
            break;
        case "/fair":
            data.label = "menu.exhibition_module";
            data.icon = exhibitionModuleWhite;
            break;
        case "/oscart":
            data.label = "menu.oscart";
            data.icon = tvIconWhite;
            break;
        default:
            data.label = "menu.order";
            data.icon = homeWhite;
            break;
    }

    return data
};

export default getInformationsAboutActivePage;